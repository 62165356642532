import { actionTypes } from '@Reducers/sections/actionTypes';

export const initialSectionsState = {
	campaigns: false,
	news: [],
	readNews: [],
	footerContent: null,
	latestRelease: undefined,
	headerContent: {
		menuItems: [],
		redDotAvailable: false,
	},
	sidebar: [],
	sidebarLoading: true,
	readNewsLoading: true,
	platformSearchPrevRoute: [],
	luckyDrawContent: null,
	stickyToolbarTopDistance: null,
};

export const sectionsReducer = (state = initialSectionsState, action) => {
	switch (action.type) {
		case actionTypes.SET_CAMPAIGNS:
			return {
				...state,
				campaigns: action.campaigns,
			};
		case actionTypes.SET_NEWS:
			return {
				...state,
				news: action.news,
			};
		case actionTypes.SET_MENU:
			return {
				...state,
				headerContent: action.headerContent,
			};
		case actionTypes.SET_NEWSLETTER:
		case actionTypes.SET_FOOTER_MENU_EXTERNAL_URLS:
		case actionTypes.SET_FOOTER_GROUPS:
		case actionTypes.SET_FOOTER_ORDER:
			return {
				...state,
				footerContent: action.footerContent,
			};
		case actionTypes.GET_SIDEBAR_CONTENT:
			return {
				...state,
				sidebar: action.sidebar,
				sidebarLoading: action.sidebarLoading,
			};
		case actionTypes.SET_PLATFORM_SEARCH_PREVIOUS_ROUTE:
			return {
				...state,
				platformSearchPrevRoute: action.platformSearchPrevRoute,
			};
		case actionTypes.GET_LATEST_RELEASE:
			return {
				...state,
				latestRelease: action.latestRelease,
			};
		case actionTypes.MARK_READ_NEWS:
			return {
				...state,
				readNews: action.readNews,
			};
		case actionTypes.GET_READ_NEWS:
			return {
				...state,
				readNews: action.readNews,
				readNewsLoading: action.readNewsLoading,
			};
		case actionTypes.GET_LUCKY_DRAW_CONTENT:
			return {
				...state,
				luckyDrawContent: action.luckyDrawContent,
			};
		case actionTypes.SET_STICKY_TOOLBAR_TOP_DISTANCE:
			return {
				...state,
				stickyToolbarTopDistance: action.stickyToolbarTopDistance,
			};
		default:
			return state;
	}
};

export default sectionsReducer;
