import dayjs from 'dayjs';
import { client } from '@Umbraco/client';
import isBetween from 'dayjs/plugin/isBetween';
import getHierarchicalDescendants from '@Umbraco/helpers';
import CONFIG from '@Umbraco/config.json';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import { IconType } from '@isp/icon';
import { SidebarGroups, SidebarTabOptions, SidebarTabs } from '@Constants/menu';
import { StoreKeys } from '@Constants/product';

dayjs.extend(isBetween);

export interface MenuItem {
	id: string;
	type: string;
	title: string;
	description: string;
	icon: string;
	url: string;
	hasChildren: boolean;
	children: MenuItem[];
	key: StoreKeys;
	responsive: boolean;
	newChipToggle: boolean;
}

export interface MenuProductItem {
	description: string;
	icon: string;
	id: string;
	key: StoreKeys;
	title: string;
}

const toSidebarTab = (tab, group, firstAccountTab) => ({
	identifier: tab.identifier,
	group,
	text: tab.label,
	icon: IconType[tab.icon] || SidebarTabOptions[tab.identifier]?.defaultIcon || IconType.ARROW_RIGHT,
	action: SidebarTabOptions[tab.identifier]?.action,
	route: SidebarTabOptions[(tab.identifier === SidebarTabs.ACCOUNT ? firstAccountTab : tab).identifier]?.route,
	url: tab.url,
});

const toSidebarGroup = (item, firstAccountTab) =>
	item.tabs.map(tab => toSidebarTab(tab, item.identifier, firstAccountTab));

const toSidebar = content => {
	const firstAccountTab = find(content, group => group.identifier === SidebarGroups.ACCOUNT).tabs[0];
	return flatten(content.map(group => toSidebarGroup(group, firstAccountTab)));
};

export function getSidebar(market: string, lang: string) {
	const id = CONFIG.SIDEBAR;

	return client(`/content/${id}/descendants/1/1000`, market, lang)
		.then(res => {
			const sidebar = res?._embedded.content;
			return toSidebar(sidebar);
		})
		.catch(() => {
			return null;
		});
}

const toMenuItem = item => ({
	id: item._id,
	type: item.identifier,
	key: item.identifier,
	title: item.title,
	description: item.description,
	icon: item.icon,
	responsive: item.responsive,
	hasChildren: item._hasChildren,
	children: item.children.length > 0 ? item.children.map(child => toMenuItem(child)) : [],
	externalLinks: item?.externalLinks,
	newChipToggle: item.newChipToggle,
	customLinkoutURL: item.customLinkoutURL || '',
	url: item.url,
});

const toMenu = item => {
	const startDate = dayjs(item.redDotAvailabilityStartTime);
	const endDate = dayjs(item.redDotAvailabilityEndTime);
	const currentDate = dayjs();

	return {
		redDotAvailable: currentDate.isBetween(startDate, endDate),
		children: item.children.length > 0 ? item.children.map(child => toMenuItem(child)) : [],
	};
};

export function getMenu(market: string, lang: string) {
	const id = CONFIG.MENU;

	return client(`/content/${id}`, market, lang)
		.then(async res => {
			const { redDotAvailabilityStartTime, redDotAvailabilityEndTime } = res;
			const descendants = await client(`/content/${id}/descendants/1/1000`, market, lang);
			const content = descendants?._embedded.content;
			const menuItems = getHierarchicalDescendants(id, content);
			return toMenu({ redDotAvailabilityStartTime, redDotAvailabilityEndTime, ...menuItems });
		})
		.catch(() => null);
}
