import useEvent from '@Helpers/hooks/useEvent';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { RootState } from '@Redux';
import { CalloutPart } from '@Reducers/vehicle/models';
import { Aggregate } from '@Reducers/catalogue/models';
import { getPartWithDetails } from '@Helpers/webparts';
import { getPartPriceAndAvailability, getPartPriceAndAvailabilityByDealer } from '@Apis/shop';
import { LocalStorageKeys } from '@Helpers/storage/localStorage';
import { AggregateTypes, CatalogAggregate, LICENSE_PLATE_NOT_ALLOWED_COUNTRIES } from '@Constants/vehicle';
import { FinalVehicleType } from '@Constants/product';
import { Markets } from '@Constants/market';
import { Events, ResponseCode } from '@Constants/common';
import { FinBasedPartSearchResponseType, PART_REFRESH } from '@Constants/webparts';

export const getVehicleType = (): FinalVehicleType => {
	const store = getOrCreateStore({});
	return store.getState().vehicle.vehicleDetails.vehicleInformation.type;
};

export const getVinNumber = (): string => {
	const store = getOrCreateStore({});
	return store.getState().vehicle.vehicleDetails.vinNumber;
};

export const isLicensePlateAllowed = () => {
	const store = getOrCreateStore({});
	const market = (store.getState() as RootState).configuration.market.code;
	return !LICENSE_PLATE_NOT_ALLOWED_COUNTRIES.includes((market || '').toLowerCase() as Markets);
};

export const getVehicleListSearchPlaceholder = () => {
	const store = getOrCreateStore({});
	const market = (store.getState() as RootState).configuration.market.code;
	const { webParts } = (store.getState() as RootState).configuration.featureConfiguration;
	const isLicensePlateAllowed = !LICENSE_PLATE_NOT_ALLOWED_COUNTRIES.includes((market || '').toLowerCase() as Markets);

	switch (true) {
		case isLicensePlateAllowed && webParts:
			return 'vehicles:vehicle-and-part-search-placeholder';
		case !isLicensePlateAllowed && webParts:
			return 'vehicles:vin-and-part-search-placeholder';
		case isLicensePlateAllowed:
			return 'vehicles:vehicle-search-placeholder';
		default:
			return 'vehicles:vehicle-search-placeholder-only-vin';
	}
};

export const onExactSearch = async (exactMatchResult, searchResults, resp, userASP, orderType) => {
	let newVehiclePart = null;
	const responseType = FinBasedPartSearchResponseType.WEBPARTS;
	let { responseCode } = resp;

	const fetchPriceAndAvailability = userASP
		? getPartPriceAndAvailabilityByDealer({
				partNumbers: [exactMatchResult.partNo],
				dealerId: localStorage.getItem(LocalStorageKeys.ASP_TEST_ACCOUNT_GSSN_ID),
				customerNumber: localStorage.getItem(LocalStorageKeys.ASP_TEST_ACCOUNT_CUSTOMER_NUMBER),
				orderType,
		  })
		: getPartPriceAndAvailability([exactMatchResult.partNo], { orderType });

	await fetchPriceAndAvailability
		.then(priceResp => {
			const partDetailedInfo = priceResp.data?.partList || null;
			if (partDetailedInfo && partDetailedInfo.length > 0) {
				newVehiclePart = {
					...getPartWithDetails(
						{ ...exactMatchResult, partNumber: exactMatchResult.partNo } as CalloutPart,
						partDetailedInfo
					),
					designation: exactMatchResult.name,
					additionalDescription: exactMatchResult.description,
					navContext: searchResults[0].navContext,
				};
				const priceInfo = newVehiclePart?.shoppingDetails?.price;
				newVehiclePart = {
					...newVehiclePart,
					netPrice: priceInfo?.netPricePerUnit || 0,
					listPrice: priceInfo?.listPricePerUnit || 0,
				};
			} else if (priceResp?.responseCode === ResponseCode.NOT_FOUND) {
				responseCode = ResponseCode.NOT_FOUND;
			}
		})
		.catch(err => {
			newVehiclePart = {
				...getPartWithDetails({ ...exactMatchResult, partNumber: exactMatchResult.partNo } as CalloutPart, null),
				designation: exactMatchResult.name,
				additionalDescription: exactMatchResult.description,
				navContext: searchResults[0].navContext,
			};
			responseCode = err.response.data.ResponseCode || ResponseCode.EXTERNAL_API_EXCEPTION;
			const priceInfo = newVehiclePart?.shoppingDetails?.price;
			newVehiclePart = {
				...newVehiclePart,
				netPrice: priceInfo?.netPricePerUnit || 0,
				listPrice: priceInfo?.listPricePerUnit || 0,
			};
		});

	return { newVehiclePart, responseCode, responseType };
};

export const onFreeTextSearch = (searchResults, totalCount) => {
	let newVehiclePart = null;
	newVehiclePart = {
		searchResults: (searchResults || []).map(item => ({
			...item,
			partNumber: item.partNo,
		})),
		totalCount,
	};

	return newVehiclePart;
};

export const REFRESH_VEHICLE_LIST = 'refreshVehicleList';
export const CLEAR_VEHICLE_SEARCH = 'clearVehicleSearch';
export const REFRESH_VIN_SAVE = 'refreshVinSave';
export const PPV_UNLOCK_VIN = 'ppvUnlockVin';

export const refreshVehicleList = () => {
	window.postMessage(REFRESH_VEHICLE_LIST);
};

export const useVehiclesRefresh = callback => {
	useEvent(Events.MESSAGE, (e: MessageEvent) => {
		if (e.data === REFRESH_VEHICLE_LIST) {
			callback();
		}
	});
};

export const refreshPartList = () => {
	window.postMessage(PART_REFRESH);
};

export const usePartsRefresh = callback => {
	useEvent(Events.MESSAGE, (e: MessageEvent) => {
		if (e.data === PART_REFRESH) {
			callback();
		}
	});
};

export const clearVehicleSearch = () => {
	window.postMessage(CLEAR_VEHICLE_SEARCH);
};

export const useVehicleSearchClear = callback => {
	useEvent(Events.MESSAGE, (e: MessageEvent) => {
		if (e.data === CLEAR_VEHICLE_SEARCH) {
			callback();
		}
	});
};

export const refreshCatalogueVinSave = () => {
	window.postMessage(REFRESH_VIN_SAVE);
};

export const useCatalogueVinSaveRefresh = callback => {
	useEvent(Events.MESSAGE, (e: MessageEvent) => {
		if (e.data === REFRESH_VIN_SAVE) {
			callback();
		}
	});
};

export const getAggregateTranslation = (t, aggregate: CatalogAggregate | Aggregate) => {
	const aggregateTypes = Object.keys(AggregateTypes);
	const aggregateKey = aggregateTypes.find(agg => agg === aggregate.key);

	return t(`vehicles:catalog-aggregate-${AggregateTypes[aggregateKey].toLowerCase()}`);
};
