import orderBy from 'lodash/orderBy';
import { ChapterStatus } from '@isp/chapter-button';
import {
	getVehicleInfo,
	saveVehicle,
	deleteVehicle,
	getVehicleVinValidation,
	getUserVehiclesOrPart,
	getSearchedPart,
	getFilteredVehicleList,
	getVinPart,
} from '@Apis/index';
import {
	getDsbDetailedServiceInformation,
	getKDMInfo,
	getRecentlyOpenedVehicles,
	getServiceDocumentation,
	getServiceHistory,
	getServiceHistoryDetail,
	getToBeDeletedVehicleCount,
	getVehicleConsentInfo,
	getVehicleDetails,
	getVehicleDwdAvailability,
	getFillingData,
	selectAggregate,
	getCatalogsWithVIN,
	getNonSavedVehicleDetails,
	getPartFastSearchWithoutContext,
	getVehicleNote,
} from '@Apis/vehicle';
import { getPartPriceAndAvailability, getPartPriceAndAvailabilityByDealer } from '@Apis/shop';
import { actionTypes } from '@Reducers/vehicle/actionTypes';
import { initialVehicleState } from '@Reducers/vehicle/reducers';
import { GET_CONSENT_SETTINGS } from '@Reducers/organization/actions';
import { CompanyAddress, GeneralSearchPart, VehicleNoteWithVin } from '@Reducers/vehicle/models';
import { TOGGLE_MODEL_RANGE_SELECTION_MODAL } from '@Reducers/modals/actions';
import { actionTypes as modalActionTypes } from '@Reducers/modals/actionTypes';
import { parseDsbDocumentationData } from '@Helpers/dsb';
import { parseMbSpecs } from '@Helpers/vehicle/getFillingData';
import { getCodeFromMultipleResponse } from '@Helpers/vehicle/getVehicleOrPartResponse';
import { clearVehicleSearch, onExactSearch, onFreeTextSearch, refreshVehicleList } from '@Helpers/vehicle';
import { getCancelToken } from '@Network/index';
import { validateVin } from '@Helpers/vehicle/validateVin';
import { LocalStorageKeys } from '@Helpers/storage/localStorage';
import { HTTPResponseCodes, ResponseCode } from '@Constants/common';
import {
	CHAPTER_TYPES,
	INITIAL_VEHICLE_DETAILS_DATA,
	VALIDATION_TRIGGER_INITIAL_VALUE,
	VehicleSection,
	DEFAULT_VEHICLE_LIST_PAGE_INDEX,
	DEFAULT_VEHICLE_LIST_PAGE_SIZE,
	ServiceDocumentationModalType,
	COMPLETED_SERVICE_CALL_COUNT,
	DSBResponseStatus,
	SortingButtonGroupType,
	DEFAULT_VEHICLE_LIST_FILTER_TYPE,
	AddWork,
	AggregateSelection,
	VehicleCatalog,
	DSBMileageType,
} from '@Constants/vehicle';
import { FinBasedPartSearchResponseType } from '@Constants/webparts';
import { UserRoles } from '@Constants/user';

export const GET_KDM_INFO = vin => dispatch => {
	dispatch({ type: actionTypes.GET_KDM_INFO, isKDMLoading: true });
	return getKDMInfo(vin)
		.then(res => {
			dispatch({
				type: actionTypes.GET_KDM_INFO,
				fieldMeasureData: res?.fieldMeasures || null,
				isKDMLoading: false,
			});
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_KDM_INFO,
				fieldMeasureData: null,
				isKDMLoading: false,
			});
		});
};

export const GET_VEHICLE_DETAILS = (vin: string) => (dispatch, getState) => {
	const selectedCatalog = getState().vehicle?.selectedCatalog;
	const catalogs = getState().vehicle?.catalogs;
	const preSelectedCatalog = catalogs && catalogs.find(catalog => catalog.preselected === true);
	const isAllAggregateSelected =
		selectedCatalog?.isAllAggregateSelected || preSelectedCatalog?.isAllAggregateSelected || false;
	dispatch(TOGGLE_ALL_SERVICES(false));
	dispatch({
		type: actionTypes.GET_VEHICLE_DETAILS,
		vehicleDetails: INITIAL_VEHICLE_DETAILS_DATA,
		vehicleDetailsLoading: true,
		vehicleDetailsError: false,
	});
	return getVehicleDetails(vin)
		.then(resp => {
			if (!isAllAggregateSelected && resp.vehicleInformation.isOldVehicle) {
				dispatch(SHOW_OLD_VEHICLE_MARK());
			} else {
				dispatch(CLEAR_OLD_VEHICLE_REMARK());
			}
			dispatch(GET_CONSENT_SETTINGS(vin));
			dispatch({
				type: actionTypes.GET_VEHICLE_DETAILS,
				vehicleDetails: resp,
				vehicleDetailsLoading: false,
				vehicleDetailsError: false,
			});
			return resp;
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_VEHICLE_DETAILS,
				vehicleDetails: INITIAL_VEHICLE_DETAILS_DATA,
				vehicleDetailsLoading: false,
				vehicleDetailsError: true,
			});
		});
};

export const GET_NON_SAVED_VEHICLE_DETAILS = (vin: string) => (dispatch, getState) => {
	const selectedCatalog = getState().vehicle?.selectedCatalog;
	const isAllAggregateSelected = selectedCatalog?.isAllAggregateSelected || false;
	dispatch(TOGGLE_ALL_SERVICES(false));
	dispatch({
		type: actionTypes.GET_NON_SAVED_VEHICLE_DETAILS,
		vehicleDetails: INITIAL_VEHICLE_DETAILS_DATA,
		vehicleDetailsLoading: true,
		vehicleDetailsError: false,
	});
	return getNonSavedVehicleDetails(vin)
		.then(resp => {
			if (!isAllAggregateSelected && resp.vehicleInformation.isOldVehicle) {
				dispatch(SHOW_OLD_VEHICLE_MARK());
			} else {
				dispatch(CLEAR_OLD_VEHICLE_REMARK());
			}
			dispatch(GET_CONSENT_SETTINGS(vin));
			dispatch({
				type: actionTypes.GET_NON_SAVED_VEHICLE_DETAILS,
				vehicleDetails: resp,
				vehicleDetailsLoading: false,
				vehicleDetailsError: false,
			});
			return resp;
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_NON_SAVED_VEHICLE_DETAILS,
				vehicleDetails: INITIAL_VEHICLE_DETAILS_DATA,
				vehicleDetailsLoading: false,
				vehicleDetailsError: true,
			});
		});
};

export const GET_FILTERED_VEHICLE_LIST = (
	filterType = DEFAULT_VEHICLE_LIST_FILTER_TYPE,
	pageIndex = DEFAULT_VEHICLE_LIST_PAGE_INDEX,
	pageSize = DEFAULT_VEHICLE_LIST_PAGE_SIZE
) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.GET_FILTERED_VEHICLE_LIST,
		vehicles: getState().vehicle.vehicles,
		vehiclesLoading: true,
		vehiclesError: false,
	});
	return getFilteredVehicleList(filterType, pageIndex, pageSize)
		.then(resp => {
			const vehicles = {
				count: resp.count,
				totalCount: resp.totalCount,
				list: resp.data || [],
			};
			dispatch({
				type: actionTypes.GET_FILTERED_VEHICLE_LIST,
				vehicles,
				vehiclesLoading: false,
				vehiclesError: false,
			});
			return vehicles;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_FILTERED_VEHICLE_LIST,
				vehicles: {
					count: 0,
					totalCount: 0,
					list: [],
				},
				vehiclesLoading: false,
				vehiclesError: true,
				vehicleListPageIndex: DEFAULT_VEHICLE_LIST_PAGE_INDEX,
			});
			throw err;
		});
};

export const SET_VEHICLE_LIST = (vehicles, totalCount) => dispatch => {
	const newList = { list: vehicles, totalCount };
	dispatch({ type: actionTypes.SET_VEHICLE_LIST, vehicles: newList });
};

export const SET_FILTERED_VEHICLE_LIST = (vehicles, totalCount) => dispatch => {
	const newList = { list: vehicles, totalCount };
	dispatch({ type: actionTypes.SET_FILTERED_VEHICLE_LIST, vehicles: newList });
};

export const CLEAR_VEHICLE_DETAILS = () => dispatch => {
	dispatch({
		type: actionTypes.CLEAR_VEHICLE_DETAILS,
		vehicleDetails: INITIAL_VEHICLE_DETAILS_DATA,
		vehicleDetailsLoading: false,
		vehicleDetailsError: false,
	});
};

export const GET_USER_VEHICLES_OR_PART = ({ options = null }) => (dispatch, getState) => {
	const { vehicles, part, generalSearchKeyword } = getState().vehicle;
	dispatch({
		type: actionTypes.GET_USER_VEHICLES_OR_PART,
		vehicles,
		part,
		vehiclesLoading: true,
		isResultPart: false,
	});
	return getUserVehiclesOrPart(options)
		.then(resp => {
			const vehicleResp = resp?.vehicleResult || null;
			const partResp = resp?.partInfoResult || null;
			const partRespCode = partResp?.responseCode || ResponseCode.BAD_REQUEST;
			const vehicleRespCode = vehicleResp?.responseCode || ResponseCode.BAD_REQUEST;
			switch (true) {
				case partRespCode === ResponseCode.SUCCESS:
					dispatch(SET_SEARCHED_PART(partResp, partRespCode));
					break;
				case vehicleRespCode === ResponseCode.SUCCESS:
					dispatch(SET_SEARCHED_VEHICLES(vehicleResp, vehicleRespCode));
					break;
				default:
					dispatch({
						type: actionTypes.GET_USER_VEHICLES_OR_PART,
						vehicles,
						part: null,
						vehiclesLoading: false,
						isResultPart: false,
						isResultValidVin: validateVin(generalSearchKeyword),
						searchResponseCode: getCodeFromMultipleResponse([partRespCode, vehicleRespCode]),
					});
					break;
			}
			return { vehicles, part };
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_USER_VEHICLES_OR_PART,
				vehicles: {
					count: 0,
					totalCount: 0,
					list: [],
				},
				part: null,
				vehiclesLoading: false,
				partLoading: false,
				isResultPart: false,
				searchResponseCode: null,
			});
			throw err;
		});
};

export const SET_SEARCHED_PART = (res = null, partRespCode = null) => dispatch => {
	const partInfo = res?.data?.partList[0] || null;
	const part: GeneralSearchPart = {
		partNumber: partInfo?.position?.partNumber,
		quantity: partInfo?.position?.quantity,
		isInvalidPart: partInfo?.isInvalidPart,
		isRemanPart: partInfo?.isRemanPart,
		isUnknownPart: partInfo?.isUnknownPart,
		tooltip: partInfo?.tooltip,
		designation: partInfo?.designation,
		shoppingDetails: { price: partInfo?.price, availability: partInfo?.availability, dealerInfo: null },
		description: partInfo?.description,
		additionalDescription: partInfo?.additionalDescription,
		title: partInfo?.title,
	};
	dispatch({
		type: actionTypes.SET_SEARCHED_PART,
		part,
		vehiclesLoading: false,
		searchResponseCode: partRespCode,
		isResultPart: true,
	});

	dispatch(GET_FILTERED_VEHICLE_LIST());
};

export const SET_SEARCHED_VEHICLES = (res = null, vehicleRespCode = null) => dispatch => {
	const vehicles = {
		count: res.count,
		totalCount: res.data.length,
		list: res.data,
	};
	dispatch({
		type: actionTypes.SET_SEARCHED_VEHICLES,
		vehicles,
		vehiclesLoading: false,
		searchResponseCode: vehicleRespCode,
		isResultPart: false,
	});
};

export const GET_VEHICLE_PARTS_BY_VIN_NUMBER = ({
	partText = null,
	fin = null,
	selectedPartLanguage = '',
	catalogueFilterOptions = null,
	orderType,
}) => (dispatch, getState) => {
	const { vehiclePart } = getState().vehicle;
	const { user } = getState();
	const userASP = user.role?.includes(UserRoles.ASP);

	dispatch({
		type: actionTypes.GET_VEHICLE_PARTS_BY_VIN_NUMBER,
		vehiclePart,
		partLoading: true,
	});

	return getSearchedPart(partText, fin, selectedPartLanguage, catalogueFilterOptions, orderType)
		.then(async resp => {
			let newVehiclePart = null;
			let responseType = FinBasedPartSearchResponseType.NONE;
			let { responseCode } = resp;
			if (resp.responseCode === ResponseCode.SUCCESS) {
				const { searchResults, totalCount } = resp.data || {};
				// Exact Match
				const exactMatchResult = searchResults && (searchResults[0]?.partInfo || null);
				// Free Text Match
				const textSearchResult = searchResults?.length >= 1;
				// Exact Match Result
				if (exactMatchResult) {
					({ newVehiclePart, responseType, responseCode } = await onExactSearch(
						exactMatchResult,
						searchResults,
						resp,
						userASP,
						orderType
					));
				}
				if (textSearchResult && (responseCode === ResponseCode.NOT_FOUND || !exactMatchResult)) {
					responseType = FinBasedPartSearchResponseType.IPARTS;
					responseCode = ResponseCode.SUCCESS;
					newVehiclePart = onFreeTextSearch(searchResults, totalCount);
				}
			}

			dispatch({
				type: actionTypes.GET_VEHICLE_PARTS_BY_VIN_NUMBER,
				vehiclePart: newVehiclePart,
				partLoading: false,
				partSearchResponseCode: responseCode,
				partSearchResponseType: responseType,
			});
			return resp;
		})
		.catch(() => {
			dispatch(GET_SEARCH_PARTS_BY_PART_NUMBER({ partText, isSequentialCall: true, selectedPartLanguage, orderType }));
		});
};

export const GET_VEHICLE_PARTS_BY_VIN_AND_PART_NUMBER = ({
	partText = null,
	fin = null,
	selectedPartLanguage = '',
	catalogueFilterOptions = null,
	orderType,
	modelId = null,
}) => (dispatch, getState) => {
	const { vehiclePart } = getState().vehicle;
	const { user } = getState();
	const { isASPUser } = user;

	dispatch({
		type: actionTypes.GET_VEHICLE_PARTS_BY_VIN_AND_PART_NUMBER,
		vehiclePart,
		partLoading: true,
	});

	return getVinPart(partText, fin, selectedPartLanguage, catalogueFilterOptions, orderType, modelId)
		.then(async resp => {
			let newVehiclePart = null;
			let responseType = FinBasedPartSearchResponseType.NONE;
			let { responseCode } = resp;
			if (resp.responseCode === ResponseCode.SUCCESS) {
				const { searchResults, totalCount, exactMatchFound } = resp.data || {};
				const partTextWithoutSpaces = partText.replace(/\s/g, '');
				const partItem = searchResults.find(item => item.partNo === partTextWithoutSpaces);

				// Exact Match Result
				if (exactMatchFound) {
					({ newVehiclePart, responseType, responseCode } = await onExactSearch(
						partItem,
						searchResults,
						resp,
						isASPUser,
						orderType
					));
				}
				if (responseCode === ResponseCode.NOT_FOUND || !exactMatchFound) {
					responseType = FinBasedPartSearchResponseType.IPARTS;
					responseCode = ResponseCode.SUCCESS;
					newVehiclePart = onFreeTextSearch(searchResults, totalCount);
				}
			}
			dispatch({
				type: actionTypes.GET_VEHICLE_PARTS_BY_VIN_AND_PART_NUMBER,
				vehiclePart: newVehiclePart,
				partLoading: false,
				partSearchResponseCode: responseCode,
				partSearchResponseType: responseType,
			});
			return resp;
		})
		.catch(() => {
			dispatch(GET_SEARCH_PARTS_BY_PART_NUMBER({ partText, isSequentialCall: true, selectedPartLanguage, orderType }));
		});
};

export const GET_SEARCH_PARTS_BY_PART_NUMBER = ({
	partText = null,
	isSequentialCall = null,
	isAvailableTextSearch = true,
	selectedPartLanguage,
	orderType,
}) => (dispatch, getState) => {
	const { vehiclePart } = getState().vehicle;
	const { user } = getState();
	const { isASPUser } = user;
	const { EXTERNAL_API_EXCEPTION } = ResponseCode;

	dispatch({
		type: actionTypes.GET_SEARCH_PARTS_BY_PART_NUMBER,
		vehiclePart,
		partLoading: true,
	});
	const params = {
		...(isASPUser && {
			partNumbers: [partText],
			dealerId: localStorage.getItem(LocalStorageKeys.ASP_TEST_ACCOUNT_GSSN_ID),
			customerNumber: localStorage.getItem(LocalStorageKeys.ASP_TEST_ACCOUNT_CUSTOMER_NUMBER),
		}),
		isSearch: true,
		orderType,
	};
	const fetchPriceAndAvailability = isASPUser
		? getPartPriceAndAvailabilityByDealer(params)
		: getPartPriceAndAvailability([partText], params);
	return fetchPriceAndAvailability
		.then(async resp => {
			let responseCode =
				resp.responseCode === ResponseCode.NOT_FOUND && isSequentialCall
					? ResponseCode.EXTERNAL_API_EXCEPTION
					: resp?.responseCode;
			const partInfo = resp.data?.partList?.[0] || null;
			let responseType = FinBasedPartSearchResponseType.NONE;
			let newVehiclePart = null;

			if (partInfo) {
				const { position } = partInfo;
				newVehiclePart = { ...partInfo, partNumber: position?.partNumber, quantity: position?.quantity };
				responseType = FinBasedPartSearchResponseType.WEBPARTS;
			}

			if (responseCode === ResponseCode.NOT_FOUND && isAvailableTextSearch) {
				await getPartFastSearchWithoutContext(partText, selectedPartLanguage).then(res => {
					if (res.data && res.data.resultList) {
						responseCode = ResponseCode.SUCCESS;
						dispatch(TOGGLE_MODEL_RANGE_SELECTION_MODAL({ results: res.data.resultList }));
					}
				});
			}
			dispatch({
				type: actionTypes.GET_SEARCH_PARTS_BY_PART_NUMBER,
				vehiclePart: newVehiclePart,
				partLoading: false,
				partSearchResponseCode: responseCode,
				partSearchResponseType: responseType,
			});
			return { ...resp, responseCode };
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_SEARCH_PARTS_BY_PART_NUMBER,
				vehiclePart: null,
				partLoading: false,
				partSearchResponseCode:
					err.response?.status === HTTPResponseCodes.FAILED_DEPENDENCY ? EXTERNAL_API_EXCEPTION : null,
				partSearchResponseType: FinBasedPartSearchResponseType.NONE,
			});
			throw err;
		});
};

export const GET_VEHICLE_INFO = (vinNumber: string, isDatacardExist: boolean) => dispatch => {
	dispatch({
		type: actionTypes.GET_VEHICLE_INFO,
		vehicleInfoLoading: true,
	});
	return getVehicleInfo(vinNumber, isDatacardExist)
		.then(vehicleInfo => {
			dispatch({
				type: actionTypes.GET_VEHICLE_INFO,
				vehicleInfoLoading: false,
			});
			return vehicleInfo;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_VEHICLE_INFO,
				vehicleInfoLoading: false,
			});
			throw err;
		});
};

export const GET_VEHICLE_VIN_VALIDATION = (vinNumber: string) => dispatch => {
	dispatch({
		type: actionTypes.GET_VEHICLE_VIN_VALIDATION,
		vehicleVinValidationLoading: true,
	});
	return getVehicleVinValidation(vinNumber)
		.then(vehicleVinValidation => {
			dispatch({
				type: actionTypes.GET_VEHICLE_VIN_VALIDATION,
				vehicleVinValidationLoading: false,
			});
			return vehicleVinValidation;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_VEHICLE_VIN_VALIDATION,
				vehicleVinValidationLoading: false,
			});
			throw err;
		});
};

export const SAVE_VEHICLE = (
	vin: string,
	body: {
		plateNumber: string;
		customerFullName: string;
	}
) => dispatch => {
	dispatch({
		type: actionTypes.SAVE_VEHICLE,
		vehicleSaveLoading: true,
	});
	return saveVehicle(vin, body)
		.then(vehicleSaved => {
			dispatch({
				type: actionTypes.SAVE_VEHICLE,
				vehicleSaveLoading: false,
			});
			refreshVehicleList();
			clearVehicleSearch();
			return vehicleSaved;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.SAVE_VEHICLE,
				vehicleSaveLoading: false,
			});
			throw err;
		});
};

export const DELETE_VEHICLE = id => dispatch => {
	dispatch({
		type: actionTypes.DELETE_VEHICLE,
		vehicleDeleteLoading: true,
	});
	return deleteVehicle(id)
		.then(vehicleDeleted => {
			dispatch({
				type: actionTypes.DELETE_VEHICLE,
				vehicleDeleteLoading: false,
			});
			return vehicleDeleted;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.DELETE_VEHICLE,
				vehicleDeleteLoading: false,
			});
			return err;
		});
};

export const SET_DSB_SERVICES = dsbData => dispatch => {
	const orderedServices = orderBy(dsbData.services, ['date'], ['desc']);

	dispatch({
		type: actionTypes.SET_DSB_SERVICES,
		dsbData: { ...dsbData, services: orderedServices },
	});
};

export const UPDATE_SERVICE_DOCUMENTATION_FORM = (
	key = null,
	value: boolean | string | AddWork[] | DSBMileageType | CompanyAddress | Record<string, boolean> = ''
) => (dispatch, getState) => {
	const previousServiceDocumentationForm = getState().vehicle.serviceDocumentationForm;

	dispatch({
		type: actionTypes.UPDATE_SERVICE_DOCUMENTATION_FORM,
		serviceDocumentationForm: key ? { ...previousServiceDocumentationForm, [key]: value } : {},
	});
};

export const SET_SELECTED_VEHICLE = vehicle => dispatch => {
	dispatch({
		type: actionTypes.SET_SELECTED_VEHICLE,
		selectedVehicle: vehicle,
	});
};

export const SET_HIGHLIGHTED_VEHICLE_VIN = highlightedVehicleVIN => dispatch => {
	dispatch({
		type: actionTypes.SET_HIGHLIGHTED_VEHICLE_VIN,
		highlightedVehicleVIN,
	});
};

export const UPDATE_CHAPTER_NAV_BUTTON_STATUS = (chapter: CHAPTER_TYPES, status: ChapterStatus) => (
	dispatch,
	getState
) => {
	const previousChapterStatuses = getState().vehicle.chapterStatuses;

	dispatch({
		type: actionTypes.UPDATE_CHAPTER_NAV_BUTTON_STATUS,
		chapterStatuses: { ...previousChapterStatuses, [chapter]: status },
	});
};

export const UPDATE_SERVICE_DOCUMENTATION_FORM_ERRORS = (key = null, value) => (dispatch, getState) => {
	const previousServiceDocumentationErrors = getState().vehicle.serviceDocumentationFormErrors;
	dispatch({
		type: actionTypes.UPDATE_SERVICE_DOCUMENTATION_FORM_ERRORS,
		serviceDocumentationFormErrors: key ? { ...previousServiceDocumentationErrors, [key]: value } : {},
	});
};

export const UPDATE_DSB_EXPORT_ERROR = (value = false) => dispatch => {
	dispatch({
		type: actionTypes.UPDATE_DSB_EXPORT_ERROR,
		exportPDFError: value,
	});
};

export const UPDATE_KDM_EXPORT_ERROR = (value = false) => dispatch => {
	dispatch({
		type: actionTypes.UPDATE_KDM_EXPORT_ERROR,
		exportKDMPDFError: value,
	});
};

export const TRIGGER_VALIDATION = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TRIGGER_VALIDATION,
		validationTrigger: getState().vehicle.validationTrigger + 1,
	});
};

export const RESET_VALIDATION_COUNTER = () => dispatch => {
	dispatch({
		type: actionTypes.RESET_VALIDATION_COUNTER,
		validationTrigger: VALIDATION_TRIGGER_INITIAL_VALUE,
		serviceType: null,
	});
};

export const UPDATE_ADDITIONAL_CHECKBOXES_STATUS = status => dispatch => {
	dispatch({
		type: actionTypes.UPDATE_ADDITIONAL_CHECKBOXES_STATUS,
		additionalWorkSelected: status,
	});
};

export const UPDATE_FILTERED_CHECKBOXES_STATUS = status => dispatch => {
	dispatch({
		type: actionTypes.UPDATE_FILTERED_CHECKBOXES_STATUS,
		allFilteredWorksSelected: status,
	});
};

export const TOGGLE_DSB_SAVED_NOTIFICATION = (savedServiceIndex = 0) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_DSB_SAVED_NOTIFICATION,
		savedServiceIndex,
		showDsbSavedNotification: !getState().vehicle.showDsbSavedNotification,
	});
};

export const RESET_DOCUMENTATION_FORM = () => dispatch => {
	const { serviceDocumentationForm, serviceDocumentationFormErrors } = initialVehicleState;
	dispatch({
		type: actionTypes.RESET_DOCUMENTATION_FORM,
		serviceDocumentationForm,
		serviceDocumentationFormErrors,
	});
};

export const GET_DSB_EDIT_DATA = (vinNumber, serviceId) => (dispatch, getState) => {
	const cancelToken = getCancelToken();
	dispatch({
		type: actionTypes.GET_SERVICE_DOCUMENTATION,
		serviceDocumentation: null,
		serviceDocumentationLoading: true,
		serviceDocumentationError: false,
		serviceDocumentationCancelToken: cancelToken,
	});

	const serviceDocumentationPromise = getServiceDocumentation(vinNumber, cancelToken);
	const serviceInformationPromise = getDsbDetailedServiceInformation(serviceId, vinNumber, cancelToken);

	return Promise.all([serviceDocumentationPromise, serviceInformationPromise])
		.then(([documentationRes, informationRes]) => {
			const { data } = informationRes;
			const serviceDocumentation = documentationRes.data;
			const serviceDocumentationForm = parseDsbDocumentationData(data);
			dispatch({
				type: actionTypes.MAP_VEHICLE_DSB_EDIT_DATA,
				serviceDocumentationForm,
			});
			dispatch(UPDATE_SERVICE_DOCUMENTATION_FORM('serviceId', serviceId));
			dispatch({
				type: actionTypes.GET_SERVICE_DOCUMENTATION,
				serviceDocumentation,
				serviceDocumentationLoading: false,
				serviceDocumentationError: false,
			});
			dispatch({
				type: modalActionTypes.TOGGLE_SERVICE_DOCUMENTATION_MODAL,
				showServiceDocumentationModal: !getState().modals.showServiceDocumentationModal,
				serviceDocumentationModalType: ServiceDocumentationModalType.EDIT,
			});
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_SERVICE_DOCUMENTATION,
				serviceDocumentation: null,
				serviceDocumentationLoading: false,
				serviceDocumentationError: !(err && err.message === 'cancel'),
			});
		});
};

export const UPDATE_DSB_DATA = (key = null, value: boolean | string | number = '') => (dispatch, getState) => {
	const { dsbData } = getState().vehicle;
	const { serviceDocumentation: previousServiceDocumentation } = dsbData;
	dispatch({
		type: actionTypes.UPDATE_DSB_DATA,
		dsbData: {
			...dsbData,
			serviceDocumentation: key ? { ...previousServiceDocumentation, [key]: value } : {},
		},
	});
};

export const UPDATE_GENERAL_SEARCH_KEYWORD = (keyword: string) => dispatch => {
	dispatch({
		type: actionTypes.UPDATE_GENERAL_SEARCH_KEYWORD,
		generalSearchKeyword: keyword,
	});
};

export const TOGGLE_EXPORT_PDF_SELECTION = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_EXPORT_PDF_SELECTION,
		exportPDFToggled: !getState().vehicle.exportPDFToggled,
	});
};

export const ROUTE_VEHICLE_PAGE_SECTION = (pageSection: VehicleSection) => dispatch => {
	dispatch({
		type: actionTypes.ROUTE_VEHICLE_PAGE_SECTION,
		pageSection,
	});
};

export const SET_VEHICLE_LIST_PAGE_INDEX = (vehicleListPageIndex = DEFAULT_VEHICLE_LIST_PAGE_INDEX) => dispatch => {
	dispatch({
		type: actionTypes.SET_VEHICLE_LIST_PAGE_INDEX,
		vehicleListPageIndex,
	});
};

export const SET_VEHICLE_LIST_LAST_LOADED_INDEX = (
	lastLoadedVehicleIndex = DEFAULT_VEHICLE_LIST_PAGE_SIZE
) => dispatch => {
	dispatch({
		type: actionTypes.SET_VEHICLE_LIST_LAST_LOADED_INDEX,
		lastLoadedVehicleIndex,
	});
};

export const GET_SERVICE_HISTORY = (vin, showLoading = true, deleteOldData = true) => (dispatch, getState) => {
	const loadAllServices = getState().vehicle.showAllServiceRecords;
	dispatch({
		type: actionTypes.GET_SERVICE_HISTORY,
		serviceHistory: !deleteOldData ? getState().vehicle.serviceHistory : undefined,
		dsbLoading: showLoading,
		dsbError: false,
	});

	return getServiceHistory(vin, loadAllServices ? null : COMPLETED_SERVICE_CALL_COUNT)
		.then(resp => {
			const serviceHistory = resp.data;
			if (!serviceHistory) {
				dispatch({
					type: actionTypes.GET_SERVICE_HISTORY,
					serviceHistory: {
						externalAPIStatus: DSBResponseStatus.GENERAL_EXCEPTION,
					},
					dsbLoading: false,
					dsbError: false,
				});
			} else {
				dispatch({
					type: actionTypes.GET_SERVICE_HISTORY,
					serviceHistory: { ...serviceHistory, externalAPIStatus: DSBResponseStatus.VALID },
					dsbLoading: false,
					dsbError: false,
				});
			}
			return serviceHistory;
		})
		.catch(err => {
			const externalAPIStatus = DSBResponseStatus[err?.response?.data?.data];
			dispatch({
				type: actionTypes.GET_SERVICE_HISTORY,
				serviceHistory: { externalAPIStatus },
				nextService: null,
				dsbLoading: false,
				dsbError: true,
			});
		});
};

export const GET_SERVICE_HISTORY_DETAIL = (vin, serviceId) => dispatch => {
	dispatch({
		type: actionTypes.GET_SERVICE_HISTORY_DETAIL,
		serviceHistoryDetail: null,
		serviceDetailLoading: true,
		serviceDetailError: false,
	});
	getServiceHistoryDetail(vin, serviceId)
		.then(resp => {
			const serviceHistoryDetail = resp.data;
			dispatch({
				type: actionTypes.GET_SERVICE_HISTORY_DETAIL,
				serviceHistoryDetail,
				serviceDetailLoading: false,
				serviceDetailError: false,
			});
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_SERVICE_HISTORY_DETAIL,
				serviceHistoryDetail: null,
				serviceDetailLoading: false,
				serviceDetailError: true,
			});
		});
};

export const GET_SERVICE_DOCUMENTATION = vin => dispatch => {
	const cancelToken = getCancelToken();
	dispatch({
		type: actionTypes.GET_SERVICE_DOCUMENTATION,
		serviceDocumentation: undefined,
		serviceDocumentationCancelToken: cancelToken,
	});
	return getServiceDocumentation(vin, cancelToken)
		.then(resp => {
			const serviceDocumentation = resp.data;
			dispatch({
				type: actionTypes.GET_SERVICE_DOCUMENTATION,
				isDistanceUnitMileage: serviceDocumentation.isUnitMileage,
				serviceDocumentation,
			});
			return serviceDocumentation;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_SERVICE_DOCUMENTATION,
				serviceDocumentation: null,
				serviceDocumentationLoading: false,
				serviceDocumentationError: !(err && err.message === 'cancel'),
			});
			return null;
		});
};

export const TOGGLE_ALL_SERVICES = (toggle = true) => dispatch => {
	dispatch({
		type: actionTypes.TOGGLE_ALL_SERVICES,
		showAllServiceRecords: toggle,
	});
};

export const SET_SCROLL_TO_KDM = shouldScrollToKDM => dispatch => {
	dispatch({ type: actionTypes.SET_SCROLL_TO_KDM, shouldScrollToKDM });
};

export const SET_SCROLL_TO_VEHICLE_ORDERS = shouldScrollToVehicleOrders => dispatch => {
	dispatch({ type: actionTypes.SET_SCROLL_TO_VEHICLE_ORDERS, shouldScrollToVehicleOrders });
};

export const GET_DWD_OPTION = (vin, hasActiveWISLicense) => dispatch => {
	dispatch({ type: actionTypes.GET_DWD_OPTION, isDWDAvailableForVehicle: false });

	return (
		hasActiveWISLicense &&
		getVehicleDwdAvailability(vin)
			.then(res => {
				dispatch({ type: actionTypes.GET_DWD_OPTION, isDWDAvailableForVehicle: res.data });
			})
			.catch(() => {
				dispatch({ type: actionTypes.GET_DWD_OPTION, isDWDAvailableForVehicle: false });
			})
	);
};

export const UPDATE_EXPLOSION_DRAWING_HIGHLIGHTED_PARTS = (partNumbers: string[] = [], replaceList = false) => (
	dispatch,
	getState
) => {
	if (replaceList)
		dispatch({
			type: actionTypes.UPDATE_EXPLOSION_DRAWING_HIGHLIGHTED_PARTS,
			explosionDrawingHighlightedParts: [...partNumbers],
		});
	if (partNumbers?.length > 0) {
		const outerHighlights: string[] = getState().vehicle.explosionDrawingHighlightedParts;
		partNumbers.forEach(partNumber => {
			if (!outerHighlights.includes(partNumber)) outerHighlights.push(partNumber);
		});

		dispatch({
			type: actionTypes.UPDATE_EXPLOSION_DRAWING_HIGHLIGHTED_PARTS,
			explosionDrawingHighlightedParts: [...outerHighlights],
		});
	} else
		dispatch({
			type: actionTypes.UPDATE_EXPLOSION_DRAWING_HIGHLIGHTED_PARTS,
			explosionDrawingHighlightedParts: [],
		});
};

export const SET_VEHICLE_LIST_ACTIVE_SORTING_BUTTON = (activeButtonType: SortingButtonGroupType) => dispatch => {
	dispatch({
		type: actionTypes.SET_VEHICLE_LIST_ACTIVE_SORTING_BUTTON,
		vehicleListActiveSortingButton: activeButtonType,
	});
};

export const SET_RECENT_ORDERS_FILTER_AVAILABLE = () => dispatch => {
	getVehicleConsentInfo()
		.then(resp => {
			dispatch({
				type: actionTypes.SET_RECENT_ORDERS_FILTER_AVAILABLE,
				recentOrderFilterAvailable: resp,
			});
		})
		.catch(() => {
			dispatch({
				type: actionTypes.SET_RECENT_ORDERS_FILTER_AVAILABLE,
				recentOrderFilterAvailable: false,
			});
		});
};

export const SET_UPDATED_VEHICLE_NOTE = (note: string) => dispatch => {
	dispatch({
		type: actionTypes.SET_UPDATED_VEHICLE_NOTE,
		updatedVehicleNote: note,
	});
};

export const GET_TO_BE_DELETED_VEHICLE_COUNT = () => dispatch => {
	getToBeDeletedVehicleCount()
		.then(resp => {
			dispatch({
				type: actionTypes.GET_TO_BE_DELETED_VEHICLE_COUNT,
				toBeDeletedVehicleCount: resp,
			});
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_TO_BE_DELETED_VEHICLE_COUNT,
				toBeDeletedVehicleCount: 0,
			});
		});
};

export const SET_DELETED_VEHICLE_VIN = deletedVehicleVin => dispatch => {
	dispatch({
		type: actionTypes.SET_DELETED_VEHICLE_VIN,
		deletedVehicleVin,
	});
};

export const SET_RECENTLY_OPENED_VEHICLES = () => dispatch => {
	dispatch({
		type: actionTypes.SET_RECENTLY_OPENED_VEHICLES,
		recentlyOpenedVehiclesError: false,
		recentlyOpenedVehicles: [],
	});
	getRecentlyOpenedVehicles()
		.then(response => {
			dispatch({
				type: actionTypes.SET_RECENTLY_OPENED_VEHICLES,
				recentlyOpenedVehicles: response?.data || [],
				recentlyOpenedVehiclesError: false,
			});
		})
		.catch(() => {
			dispatch({
				type: actionTypes.SET_RECENTLY_OPENED_VEHICLES,
				recentlyOpenedVehiclesError: true,
			});
		});
};

export const GET_FILLING_TAB_DATA = vinNumber => dispatch => {
	dispatch({
		type: actionTypes.GET_FILLING_TAB_DATA,
		fillingsDependencyFailed: false,
		fillingsData: undefined,
	});

	getFillingData(vinNumber)
		.then(response => {
			dispatch({
				type: actionTypes.GET_FILLING_TAB_DATA,
				fillingsDependencyFailed: false,
				fillingsData: parseMbSpecs(response),
			});
		})
		.catch(error => {
			const failedDependencyError = error.response?.status === HTTPResponseCodes.FAILED_DEPENDENCY;

			dispatch({
				type: actionTypes.GET_FILLING_TAB_DATA,
				fillingsDependencyFailed: failedDependencyError,
				fillingsData: null,
			});
		});
};

export const SELECT_AGGREGATE = (selectedAggregate: AggregateSelection, vinNumber: string) => dispatch => {
	dispatch({ type: actionTypes.SELECT_AGGREGATE, selectedAggregate, vinNumber });
	return selectAggregate(vinNumber, selectedAggregate).catch(err => {
		throw err;
	});
};

export const GET_CATALOGS_WITH_VIN = vinNumber => dispatch => {
	return getCatalogsWithVIN(vinNumber)
		.then(response => {
			dispatch({
				type: actionTypes.GET_CATALOGS_WITH_VIN,
				catalogs: response.data.catalogs,
				vehicleDataCardAvailable: response.data.catalogs[0].datacardExists,
			});
			return response.data.catalogs;
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_CATALOGS_WITH_VIN,
				catalogs: null,
				vehicleDataCardAvailable: false,
			});
		});
};

export const CLEAR_OLD_VEHICLE_REMARK = () => dispatch => {
	dispatch({ type: actionTypes.CLEAR_OLD_VEHICLE_REMARK, oldVehicleRemark: false });
};

export const SHOW_OLD_VEHICLE_MARK = () => dispatch => {
	dispatch({ type: actionTypes.SHOW_OLD_VEHICLE_REMARK, oldVehicleRemark: true });
};

export const SELECTED_CATALOG = (selectedCatalog: VehicleCatalog) => dispatch => {
	dispatch({
		type: actionTypes.SELECTED_CATALOG,
		selectedCatalog,
	});
};

export const SET_ALL_SUPPLEMENTARY_PARTS = (allSupplementaryParts = null) => dispatch => {
	dispatch({
		type: actionTypes.SET_ALL_SUPPLEMENTARY_PARTS,
		allSupplementaryParts,
	});
};

export const SET_VEHICLE_CARD_OPENED = showVehicleCard => dispatch => {
	dispatch({
		type: actionTypes.SET_VEHICLE_CARD_OPENED,
		isVehicleCardOpened: showVehicleCard,
	});
};

export const GET_VEHICLE_NOTE = vin => dispatch => {
	return getVehicleNote(vin)
		.then(res => {
			const note: VehicleNoteWithVin = { vin, vehicleNote: res.vehicleNote || null };
			dispatch({
				type: actionTypes.GET_VEHICLE_NOTE,
				vehicleNoteWithVin: note,
			});
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_VEHICLE_NOTE,
				vin,
				vehicleNoteWithVin: null,
			});
		});
};
