import { actionTypes } from '@Reducers/shop/actionTypes';

export const initialShopState = {
	basketCount: 0,
	updatedProductId: null,
	addedProductName: null,
	showBasketNotification: false,
	basketData: null,
	basketDataLoading: true,
	basketDataError: false,
	diagnosisBasketDataLoading: false,
	diagnosisBasketDataError: false,
	digitalBasketDataLoading: false,
	digitalBasketDataError: false,
	equipmentBasketDataLoading: false,
	equipmentBasketDataError: false,
	webpartsBasketDataLoading: false,
	webpartsBasketDataError: false,
	webpartsCheckoutBasket: null,
	orderAddresses: null,
	orderAddressesLoading: false,
	orderAddressesError: false,
	accessStatus: undefined,
	cartType: null,
	quantity: 0,
	addToBasketLoading: false,
	addToBasketError: false,
	lastDeletedBasketType: null,
	multipleItemsAdded: false,
	showNetPrice: null,
	errorPlaceOrderWithoutPayment: false,
	loadingPlaceOrderWithoutPayment: false,
	webpartsCheckoutComment: '',
	productsOrderedROW: [],
	lastUpdatedBasketType: null,
	cacheDataUsedDiagnosisLicense: false,
	bulkDeleteFailedBasketIds: [],
	isPartSearchInsideBasket: false,
	systemId: null,
	hardwareId: null,
	checkoutOrderAddressLoading: false,
	checkoutOrderAddressError: false,
	paymentModalOptions: null,
};

export const shopReducer = (state = initialShopState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_BASKET:
			return {
				...state,
				addToBasketLoading: action.addToBasketLoading,
				addToBasketError: action.addToBasketError,
				updatedProductId: action.updatedProductId,
				addedProductName: action.addedProductName,
				cartType: action.cartType,
				quantity: action.quantity,
				multipleItemsAdded: action.multipleItemsAdded,
				lastUpdatedBasketType: action.lastUpdatedBasketType,
			};
		case actionTypes.UPDATE_BASKET_MULTIPLE_ITEMS:
			return {
				...state,
				addToBasketLoading: action.addToBasketLoading,
				addToBasketError: action.addToBasketError,
				cartType: action.cartType,
				quantity: action.quantity,
				multipleItemsAdded: action.multipleItemsAdded,
			};
		case actionTypes.UPDATE_BASKET_WITH_VALIDATION:
			return {
				...state,
				addToBasketLoading: action.addToBasketLoading,
				addToBasketError: action.addToBasketError,
				cartType: action.cartType,
				quantity: action.quantity,
				multipleItemsAdded: action.multipleItemsAdded,
			};
		case actionTypes.GET_BASKET_TOTAL_COUNT:
			return {
				...state,
				basketCount: action.basketCount,
			};
		case actionTypes.DELETE_PRODUCT_FROM_BASKET:
			return {
				...state,
				basketDataError: action.basketDataError,
				basketDataLoading: action.basketDataLoading,
				lastDeletedBasketType: action.lastDeletedBasketType,
			};
		case actionTypes.DELETE_WEBPARTS_FROM_BASKET:
			return {
				...state,
				basketDataLoading: action.basketDataLoading,
				lastDeletedBasketType: action.lastDeletedBasketType,
			};
		case actionTypes.GET_ALL_BASKET_DATA:
			return {
				...state,
				basketData: action.basketData,
				basketDataLoading: action.basketDataLoading,
				basketDataError: action.basketDataError,
				diagnosisBasketDataLoading: action.diagnosisBasketDataLoading,
				digitalBasketDataLoading: action.digitalBasketDataLoading,
				equipmentBasketDataLoading: action.equipmentBasketDataLoading,
				webpartsBasketDataLoading: action.webpartsBasketDataLoading,
				webpartsBasketDataError: action.webpartsBasketDataError,
				equipmentBasketDataError: action.equipmentBasketDataError,
				digitalBasketDataError: action.digitalBasketDataError,
				diagnosisBasketDataError: action.diagnosisBasketDataError,
			};

		case actionTypes.GET_DIAGNOSIS_BASKET_PRODUCTS:
			return {
				...state,
				basketData: action.basketData,
				diagnosisBasketDataLoading: action.diagnosisBasketDataLoading,
				diagnosisBasketDataError: action.diagnosisBasketDataError,
			};
		case actionTypes.GET_DIGITAL_BASKET_PRODUCTS:
			return {
				...state,
				basketData: action.basketData,
				digitalBasketDataLoading: action.digitalBasketDataLoading,
				digitalBasketDataError: action.digitalBasketDataError,
			};
		case actionTypes.GET_EQUIPMENT_BASKET_PRODUCTS:
			return {
				...state,
				basketData: action.basketData,
				equipmentBasketDataLoading: action.equipmentBasketDataLoading,
				equipmentBasketDataError: action.equipmentBasketDataError,
			};
		case actionTypes.GET_WEBPARTS_BASKET_PRODUCTS:
			return {
				...state,
				basketData: action.basketData,
				webpartsBasketDataLoading: action.webpartsBasketDataLoading,
				webpartsBasketDataError: action.webpartsBasketDataError,
			};
		case actionTypes.GET_WEBPARTS_BASKET_CHECKOUT_SUMMARY:
			return {
				...state,
				webpartsCheckoutBasket: action.webpartsCheckoutBasket,
				webpartsBasketDataLoading: action.webpartsBasketDataLoading,
				webpartsBasketDataError: action.webpartsBasketDataError,
				checkoutOrderAddressLoading: action.checkoutOrderAddressLoading,
				checkoutOrderAddressError: action.checkoutOrderAddressError,
				errorPlaceOrderWithoutPayment: action.errorPlaceOrderWithoutPayment,
			};
		case actionTypes.TOGGLE_SHOPPING_BASKET_NOTIFICATION:
			return {
				...state,
				showBasketNotification: action.showBasketNotification,
			};
		case actionTypes.GET_ORDER_ADDRESSES:
			return {
				...state,
				orderAddresses: action.orderAddresses,
				orderAddressesLoading: action.orderAddressesLoading,
				orderAddressesError: action.orderAddressesError,
			};
		case actionTypes.UPDATE_HARDWARE_ID:
			return {
				...state,
				systemId: action.systemId,
				hardwareId: action.hardwareId,
			};
		case actionTypes.GET_ACCESS_STATUS:
		case actionTypes.UPDATE_ACCESS_STATUS:
			return {
				...state,
				accessStatus: action.accessStatus,
			};
		case actionTypes.SET_ROW_ORDER_PRODUCTS:
			return {
				...state,
				productsOrderedROW: action.productsOrderedROW,
			};
		case actionTypes.PLACE_WEBPARTS_ORDER:
			return {
				...state,
				loadingPlaceOrderWithoutPayment: action.loadingPlaceOrderWithoutPayment,
				errorPlaceOrderWithoutPayment: action.errorPlaceOrderWithoutPayment,
				orderedProducts: action.orderedProducts,
			};
		case actionTypes.UPDATE_LAST_DELETED_BASKET:
			return {
				...state,
				lastDeletedBasketType: action.lastDeletedBasketType,
			};
		case actionTypes.SET_SHOW_NET_PRICE:
			return {
				...state,
				showNetPrice: action.showNetPrice,
			};
		case actionTypes.SET_WEBPARTS_CHECKOUT_COMMENT:
			return {
				...state,
				webpartsCheckoutComment: action.webpartsCheckoutComment,
			};
		case actionTypes.SET_CACHE_STATUS_FOR_DIAGNOSIS_LICENSES:
			return {
				...state,
				cacheDataUsedDiagnosisLicense: action.cacheDataUsedDiagnosisLicense,
			};
		case actionTypes.BULK_DELETE_NON_WEBPARTS_BASKET:
			return {
				...state,
				basketDataError: action.basketDataError,
				basketDataLoading: action.basketDataLoading,
				lastDeletedBasketType: action.lastDeletedBasketType,
			};
		case actionTypes.BULK_DELETE_WEBPARTS_BASKET:
			return {
				...state,
				basketDataLoading: action.basketDataLoading,
				lastDeletedBasketType: action.lastDeletedBasketType,
				bulkDeleteFailedBasketIds: action.bulkDeleteFailedBasketIds,
			};
		case actionTypes.SET_PART_SEARCH_INSIDE_BASKET:
			return {
				...state,
				isPartSearchInsideBasket: action.isPartSearchInsideBasket,
			};
		case actionTypes.RESET_FAILED_DELETION_BASKET_IDS:
			return {
				...state,
				bulkDeleteFailedBasketIds: action.bulkDeleteFailedBasketIds,
			};
		case actionTypes.SET_PAYMENT_MODAL_ADD_TO_BASKET_OPTIONS:
			return {
				...state,
				paymentModalOptions: action.paymentModalOptions,
			};
		default:
			return state;
	}
};

export default shopReducer;
