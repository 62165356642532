import { getOrCreateStore } from '@Lib/with-redux-store';

export enum LocalStorageKeys {
	DESELECTED_WEBPARTS_PRODUCTS_KEY = 'deselectedWebPartsProducts',
	DESELECTED_PACKAGE_PARTS_KEY = 'deselectedPackageParts',
	CATALOGUE_FILTER_OPTIONS_KEY = 'catalogueFilterOptions',
	SHOW_NET_PRICES = 'showNetPrices',
	SELECTED_BASKET_TYPE = 'selectedBasketType',
	MAIN_GROUP_COLLAPSE = 'main-group-collapse',
	LUCKY_DRAW_DO_NOT_REMIND = 'luckyDrawDoNotRemind',
	RECOMMENDED_PART_DO_NOT_REMIND_TIME = 'recommendedPartDoNotRemindTime',
	COMPLETED_CHECKOUT_BASKET = 'completedCheckoutBasket',
	ASP_TEST_ACCOUNT_HOOK_URL = 'aspTestAccountHookURL',
	ASP_TEST_ACCOUNT_CUSTOMER_NUMBER = 'aspTestAccountCustomerNumber',
	ASP_TEST_ACCOUNT_GSSN_ID = 'aspTestAccountGssnId',
	ASP_TEST_ACCOUNT_COMPANY_ID = 'aspTestAccountCompanyId',
	MEGA_MENU_CLICKED_ITEMS = 'megaMenuClickedNewItems',
}

export const getUserSpecificLocalStorageKey = (localStorageKey: LocalStorageKeys) => {
	const store = getOrCreateStore({});
	const userName = store?.getState()?.user?.userId || '';
	return `${localStorageKey}-${btoa(userName)}`;
};

export function setItemToLocalStorage(key: string, value: any) {
	localStorage.setItem(key, JSON.stringify(value));
}

export function getItemToLocalStorage(key: string) {
	const value = localStorage.getItem(key);
	if (value !== null) return JSON.parse(value);
	return null;
}

export const isLuckyDrawContestToBeReminded = contestId => {
	return !getItemToLocalStorage(`${LocalStorageKeys.LUCKY_DRAW_DO_NOT_REMIND}-${contestId}`);
};

export const getClickedMenuItems = () => {
	if (typeof window !== 'undefined')
		return JSON.parse(localStorage.getItem(LocalStorageKeys.MEGA_MENU_CLICKED_ITEMS) || '[]') as string[];
	return [];
};
