import isEmpty from 'lodash/isEmpty';
import { toFormattedPrice } from '@isp/price';
import requestData, { parseResponseWithHeaders } from '@Network/index';
import {
	WebpartsPlaceOrderList,
	UpdateBasketMultipleItemsRequestBody,
	UpdateEquipmentBasketRequestBody,
	UpdateDigitalBasketRequestBody,
	UpdateDiagnosisBasketRequestBody,
	UpdateWebpartsBasketRequestBody,
	DeleteWebpartsRequestBody,
	PartCheckoutRequestOptions,
	DigitalBasket,
	EquipmentBasket,
	DiagnosisBasket,
	PartsBasket,
	PartBasket,
	BasketDealerInfoSummary,
	UpdateBasketWithValidationRequestBody,
} from '@Reducers/shop/models';
import { getDeselectedProducts } from '@Helpers/webparts';
import { toDiagnosisLicense } from '@Reducers/user/models';
import Equipment from '@Partials/Equipment';
import {
	DiagnosisBasketResponse,
	DiagnosisLicensesResponse,
	DigitalBasketResponse,
	EquipmentBasketResponse,
	EquipmentCatalogueProductsResponse,
	PartFileExtractionResponse,
	PartsBasketCheckoutResponse,
	PartsBasketResponse,
	RawDiagnosisBasket,
	RawDigitalBasket,
	RawEquipmentBasket,
	RetailerLegalTextResponse,
	ShoppingBasketAccessStatusResponse,
	ShopProductResponse,
	ShopProductSubscriptionPlansResponse,
	SystemContractsResponse,
} from '@IspTypes/onlineShopping/responseTypes';
import {
	ALL_SQUARE_BRACKETS,
	BasketValidationStatus,
	ShopProductKeys,
	DiagnosisAvailabilityStatus,
} from '@Constants/shop';
import { EquipmentAvailabilityStatus } from '@Constants/product/equipment';
import { OrderType } from '@Constants/orders';
import { RetailerLegalTextType } from '@Constants/retailer';
import { MIMETypes, ResponseCode } from '@Constants/common';
import { WEBPARTS_CAMPAIGN_ITEM_PER_PAGE, WEBPARTS_EMPTY_PART_NUMBER_LIST_ERROR } from '@Constants/webparts';
import { IPrice } from './orders';

export interface Package {
	packageType: string;
	callCount: string;
}

export interface ShopProductSubscriptionPlanWrapper {
	subscriptionPlans: ShopProductSubscriptionPlan[];
	purchaseAllowed: boolean;
	hasValidStateInfo: boolean;
	purchaseAllowedStatus: BasketValidationStatus;
}

export interface ShopProductSubscriptionPlan {
	productCode: string;
	productName: string;
	brand: string;
	durationType: string;
	durationAmount: string;
	rawPrice: IPrice;
	price: string;
	stockAvailability: DiagnosisAvailabilityStatus;
}

export interface EquipmentCatalogueData {
	totalCount: number;
	equipments: Equipment[];
	activePageNumber: number;
	purchaseAllowed: boolean;
}

export interface Equipment {
	availability: EquipmentAvailabilityStatus;
	equipmentName: string;
	image: string;
	itemNumber: string;
	rawPrice: IPrice;
	price: string;
	shortDescription: string;
}

interface Address {
	id: string;
	name: string;
	street: string;
	postalCode: string;
	town: string;
	region: string;
	country: string;
}
export interface DeliveryAddress {
	type: string;
	address: Address;
}
export interface OrderAddresses {
	deliveryAddresses: DeliveryAddress[];
	orderer: {
		id: string;
		name: string;
		billingAddress: Address;
	};
}

export enum SystemContractDataStatus {
	ACTIVE = 'ACTIVE',
	CLOSED = 'CLOSED',
}

export enum SystemContractDataActionId {
	EXTEND = 'EXTEND',
	NEW = 'NEW',
}

export interface SystemContractDataAction {
	id: SystemContractDataActionId;
	productId: string;
	customParameters: object[];
}

export interface SystemContractData {
	id: string;
	productId: string;
	productName: string;
	systemNumber: string;
	hardwareId: string;
	startDate: Date;
	endDate: Date;
	statusDate: string;
	status: SystemContractDataStatus;
	action: SystemContractDataAction;
}

export interface WebpartsBulkOrderItem {
	partNumber: string;
	isSuccessfullyAdded: boolean;
	quantity: number;
	finNumber?: string;
}

const getDigitalBasketAllProductPriceFormated = (basket: RawDigitalBasket): DigitalBasket => {
	const digitalProductList = basket.digitalProductList.map(digitalProduct => {
		return {
			...digitalProduct,
			rawPrice: digitalProduct.price,
			price: toFormattedPrice(digitalProduct.price),
			priceIncludingVAT: toFormattedPrice(digitalProduct.priceIncludingVAT),
		};
	});
	return { ...basket, digitalProductList, basketType: OrderType.DIGITAL };
};
const getDiagnosisBasketAllProductPriceFormated = (basket: RawDiagnosisBasket): DiagnosisBasket => {
	const diagnosisProductList = basket.diagnosisProductList.map(diagnosisProduct => {
		return {
			...diagnosisProduct,
			rawPrice: diagnosisProduct.price,
			price: toFormattedPrice(diagnosisProduct.price),
		};
	});
	return { ...basket, diagnosisProductList, basketType: OrderType.DIAGNOSIS };
};
const getEquipmentBasketAllProductPriceFormated = (basket: RawEquipmentBasket): EquipmentBasket => {
	const equipmentProductList = basket.equipmentProductList.map(equipmentProduct => {
		return {
			...equipmentProduct,
			rawPrice: equipmentProduct.price,
			price: toFormattedPrice(equipmentProduct.price),
		};
	});
	return { ...basket, equipmentProductList, basketType: OrderType.EQUIPMENT };
};
const getShopProductSubscriptionPlansFormated = (productKey): ShopProductSubscriptionPlanWrapper => {
	const subscriptionPlans = productKey.subscriptionPlans.map(plan => {
		return {
			...plan,
			rawPrice: plan.price,
			price: toFormattedPrice(plan.price),
			unformattedPrice: plan.price,
		};
	});
	return { ...productKey, subscriptionPlans, basketType: OrderType.RMI };
};
const getShopProductFormated = (productCode): ShopProductSubscriptionPlan => {
	return {
		...productCode,
		rawPrice: productCode.price,
		price: toFormattedPrice(productCode.price),
		basketType: OrderType.EQUIPMENT,
	};
};

const getEquipmentCatalogueProductsFormated = (productKey): EquipmentCatalogueData => {
	const equipments = productKey.equipments.map(equipment => {
		return {
			...equipment,
			rawPrice: equipment.price,
			price: toFormattedPrice(equipment.price),
		};
	});

	return { ...productKey, equipments, basketType: OrderType.EQUIPMENT };
};

const getPartBasketAllProductPriceFormated = (basket): PartBasket => {
	const partSubBasketList = basket.partSubBasketList.map(partSubBasket => {
		const partList = partSubBasket.partList.map(partProduct => {
			const baseDiscountAbsolute = {
				...partProduct.baseDiscountAbsolute,
				amount: partProduct.baseDiscountAbsolute.amount * -1,
				currency: partProduct.baseDiscountAbsolute.amount ? partProduct.baseDiscountAbsolute.currency : null,
			};
			return {
				...partProduct,
				sumListPrice: toFormattedPrice(partProduct.sumListPrice, true),
				sumNetPrice: toFormattedPrice(partProduct.sumNetPrice, true),
				netPricePerUnit: toFormattedPrice(
					{
						...partProduct.netPricePerUnit,
						currency: partProduct.netPricePerUnit.amount ? partProduct.netPricePerUnit.currency : null,
					},
					true
				),
				rawNetPricePerUnit: partProduct.netPricePerUnit,
				specialOffer:
					partProduct.specialOffer !== null
						? {
								...partProduct.specialOffer,
								specialOfferDiscountAbsolute: toFormattedPrice(
									{
										...partProduct.specialOffer.specialOfferDiscountAbsolute,
										amount: partProduct.specialOffer.specialOfferDiscountAbsolute.amount * -1,
										currency: partProduct.specialOffer.specialOfferDiscountAbsolute.amount
											? partProduct.specialOffer.specialOfferDiscountAbsolute.currency
											: null,
									},
									true
								),
						  }
						: null,
				listPricePerUnit: toFormattedPrice(
					{
						...partProduct.listPricePerUnit,
						currency: partProduct.listPricePerUnit.amount ? partProduct.listPricePerUnit.currency : null,
					},
					true
				),
				rawListPricePerUnit: partProduct.listPricePerUnit,
				baseDiscountAbsolute: toFormattedPrice(baseDiscountAbsolute, true),
				coreValuePerUnit: toFormattedPrice(partProduct.coreValuePerUnit),
			};
		});
		return { ...partSubBasket, partList };
	});
	return { ...basket, partSubBasketList };
};

const getPartShoppingBasketAllProductPriceFormated = (basket: PartsBasket): PartsBasket => {
	const partBasketList = basket?.partBasketList?.map(partBasket => getPartBasketAllProductPriceFormated(partBasket));
	return { ...basket, partBasketList, basketType: OrderType.WEBPARTS };
};

export function getOrderAddresses() {
	return requestData('GET', 'ORDER_ADDRESSES')
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getDigitalBasketProducts(useTimeZone?: boolean) {
	return requestData('GET', 'GET_DIGITAL_BASKET_PRODUCTS', { useTimeZone })
		.then((res: DigitalBasketResponse) => {
			return getDigitalBasketAllProductPriceFormated(res);
		})
		.catch(err => {
			const basketError = { ...err };
			throw basketError;
		});
}

export function getDiagnosisBasketProducts(useTimeZone?: boolean) {
	return requestData('GET', 'GET_DIAGNOSIS_BASKET_PRODUCTS', { useTimeZone })
		.then((res: DiagnosisBasketResponse) => {
			return getDiagnosisBasketAllProductPriceFormated(res);
		})
		.catch(err => {
			const basketError = { ...err, basketType: OrderType.DIAGNOSIS };
			throw basketError;
		});
}

export function getEquipmentBasketProducts(useTimeZone?: boolean) {
	return requestData('GET', 'GET_EQUIPMENT_BASKET_PRODUCTS', { useTimeZone })
		.then((res: EquipmentBasketResponse) => {
			return getEquipmentBasketAllProductPriceFormated(res);
		})
		.catch(err => {
			const basketError = { ...err, basketType: OrderType.EQUIPMENT };
			throw basketError;
		});
}

export function getWebpartsBasketProducts(useTimeZone?: boolean, dealerId?: string, customerNumber?: string) {
	const deselectedPartIds = JSON.stringify(getDeselectedProducts()).replace(ALL_SQUARE_BRACKETS, '') || undefined;

	return requestData('GET', 'GET_WEBPARTS_BASKET_PRODUCTS', {
		data: { deselectedPartIds, customerNumber, dealerId },
		useTimeZone,
	})
		.then((res: PartsBasketResponse) => {
			return getPartShoppingBasketAllProductPriceFormated(res);
		})
		.catch(err => {
			const basketError = { ...err, basketType: OrderType.WEBPARTS };
			throw basketError;
		});
}

export function getWebpartsCheckoutBasket(
	partCheckoutRequestOptions: PartCheckoutRequestOptions,
	useTimeZone?: boolean
) {
	const { partBasketCheckoutSummaryDealerList, deselectedPartIds, finNumber } = partCheckoutRequestOptions;
	return requestData('POST', 'GET_WEBPARTS_BASKET_CHECKOUT_SUMMARY', {
		bodyData: { finNumber, partBasketCheckoutSummaryDealerList, deselectedPartIds },
		useTimeZone,
	})
		.then((res: PartsBasketCheckoutResponse) => {
			return getPartBasketAllProductPriceFormated(res);
		})
		.catch(err => {
			throw err;
		});
}

export function getShopProductSubscriptionPlans(
	productKey: ShopProductKeys
): Promise<ShopProductSubscriptionPlanWrapper> {
	return requestData('GET', 'SHOP_PRODUCT_SUBSCRIPTION_PLANS', {
		interpolate: { productKey },
	})
		.then((res: ShopProductSubscriptionPlansResponse) => {
			return getShopProductSubscriptionPlansFormated(res);
		})
		.catch(err => {
			throw err;
		});
}

export function getShopProduct(productCode: string) {
	return requestData('GET', 'SHOP_PRODUCT', {
		interpolate: { productCode },
	})
		.then((res: ShopProductResponse) => {
			return getShopProductFormated(res);
		})
		.catch(err => {
			throw err;
		});
}

export function getEquipmentCatalogueProducts(pageNumber, pageSize, term, preferredLanguage) {
	return requestData('GET', 'EQUIPMENT_CATALOGUE_PRODUCTS', {
		data: { pageSize, pageNumber, term },
		preferredLanguage,
	})
		.then((res: EquipmentCatalogueProductsResponse) => {
			return getEquipmentCatalogueProductsFormated(res.data);
		})
		.catch(err => {
			throw err;
		});
}

export function getBasketTotalCount(params = {}) {
	return requestData('GET', 'BASKET_TOTAL_COUNT', { useTimeZone: true, data: { ...params } })
		.then((res: number) => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function updateBasketMultipleItems(data: UpdateBasketMultipleItemsRequestBody) {
	return requestData('POST', 'UPDATE_BASKET_MULTIPLE_ITEMS', { data, useTimeZone: true })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function updateBasketWithValidation(data: UpdateBasketWithValidationRequestBody) {
	return requestData('POST', 'UPDATE_BASKET_WITH_VALIDATION', { data, useTimeZone: true })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function updateEquipmentBasket(data: UpdateEquipmentBasketRequestBody, useTimeZone?: boolean) {
	return requestData('POST', 'UPDATE_EQUIPMENT_BASKET', { data, useTimeZone })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function updateDigitalBasket(data: UpdateDigitalBasketRequestBody, useTimeZone?: boolean) {
	return requestData('POST', 'UPDATE_DIGITAL_BASKET', { data, useTimeZone })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function updateDiagnosisBasket(data: UpdateDiagnosisBasketRequestBody, useTimeZone?: boolean) {
	return requestData('POST', 'UPDATE_DIAGNOSIS_BASKET', { data, useTimeZone })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function updateWebpartsBasket(bodyData: UpdateWebpartsBasketRequestBody, useTimeZone?: boolean) {
	return requestData('POST', 'UPDATE_WEBPARTS_BASKET', { bodyData, useTimeZone })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function deleteEquipmentProduct(dbItemId) {
	return requestData('DELETE', 'DELETE_EQUIPMENT_PRODUCT_FROM_BASKET', {
		interpolate: { shoppingBasketProductId: dbItemId },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function deleteDigitalProduct(dbItemId) {
	return requestData('DELETE', 'DELETE_DIGITAL_PRODUCT_FROM_BASKET', {
		interpolate: { shoppingBasketProductId: dbItemId },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}
export function deleteDiagnosisProduct(dbItemId) {
	return requestData('DELETE', 'DELETE_DIAGNOSIS_PRODUCT_FROM_BASKET', {
		interpolate: { shoppingBasketProductId: dbItemId },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function deleteWebpartsFromBasket(bodyData: DeleteWebpartsRequestBody) {
	return requestData('DELETE', 'UPDATE_WEBPARTS_BASKET', {
		bodyData,
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getSystemContracts(productName?: string) {
	return requestData('GET', 'SYSTEM_CONTRACTS', { interpolate: { productName } })
		.then((res: SystemContractsResponse) => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function updateHardwareId(systemNumber, hardwareId) {
	return requestData('PUT', 'CHANGE_HARDWARE_ID', { interpolate: { systemNumber, hardwareId } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getAccessStatus() {
	return requestData('GET', 'GET_SHOPPING_BASKET_ACCESS_STATUS')
		.then((res: ShoppingBasketAccessStatusResponse) => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function demandPurchaseAuthorization(email: string, reason: string) {
	return requestData('POST', 'DEMAND_PURCHASE_AUTHORIZATION', { data: { email, reason } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function placeOrderROWMarkets(shoppingBasketId: number, cartType: OrderType) {
	return requestData('POST', 'PLACE_ORDER_WITHOUT_PAYMENT', {
		bodyData: { shoppingBasketId, cartType, isCostToBeRounded: false },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function webpartsOrderCheckout(args: {
	partSubBasketOrderList: WebpartsPlaceOrderList[];
	clientOrderNumber: string;
	comment: string;
}) {
	const { clientOrderNumber, partSubBasketOrderList, comment } = args;
	return requestData('POST', 'WEBPARTS_ORDER_CHECKOUT', {
		bodyData: {
			clientOrderNumber,
			PartSubBasketOrderList: partSubBasketOrderList,
			comment,
		},
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getPartPriceAndAvailability(partNumbers, params = {}) {
	if (isEmpty(partNumbers)) Promise.reject(WEBPARTS_EMPTY_PART_NUMBER_LIST_ERROR);
	return requestData('POST', 'PART_PRICE_AND_AVAILABILITY', {
		bodyData: partNumbers,
		data: { ...params },
		useTimeZone: true,
	})
		.then(res => {
			if (res.responseCode === ResponseCode.SUCCESS) {
				const { dealerInfo } = res.data;
				const partList = res.data.partList.map(part => {
					const { coreValuePerUnit, listPricePerUnit, netPricePerUnit, specialOffer } = part?.price || {};
					const { specialOfferDiscountAbsolute } = specialOffer || {};
					const productSuggestionPrice: IPrice = part?.price
						? {
								...listPricePerUnit,
								amount: listPricePerUnit.amount - coreValuePerUnit.amount,
						  }
						: { ...listPricePerUnit };
					const newPrice = {
						...part.price,
						productSuggestion: toFormattedPrice(productSuggestionPrice),
						coreValuePerUnit: toFormattedPrice(coreValuePerUnit),
						listPricePerUnit: toFormattedPrice(listPricePerUnit),
						rawListPricePerUnit: listPricePerUnit,
						netPricePerUnit: toFormattedPrice(netPricePerUnit),
						rawNetPricePerUnit: netPricePerUnit,
						specialOffer: !isEmpty(specialOffer) && {
							...specialOffer,
							specialOfferDiscountAbsolute: `- ${toFormattedPrice(specialOfferDiscountAbsolute)}`,
						},
					};
					return {
						...part,
						price: newPrice,
						shoppingDetails: {
							price: newPrice,
							availability: part.availability,
							dealerInfo,
						},
					};
				});
				return { ...res, data: { ...res.data, partList } };
			}
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getPartPriceAndAvailabilityByDealer(params = {}) {
	return requestData('GET', 'PART_PRICE_AND_AVAILABILITY_FOR_SPECIFIC_DEALER', {
		data: { ...params },
		useTimeZone: true,
	})
		.then(res => {
			if (res.responseCode === ResponseCode.SUCCESS) {
				const { dealerCustomerInfo } = res.data;
				const partList = res.data.partList.map(part => {
					const { coreValuePerUnit, listPricePerUnit, netPricePerUnit, specialOffer } = part?.price || {};
					const { specialOfferDiscountAbsolute } = specialOffer || {};
					const productSuggestionPrice: IPrice = part?.price
						? {
								...listPricePerUnit,
								amount: listPricePerUnit.amount - coreValuePerUnit.amount,
						  }
						: { ...listPricePerUnit };
					const newPrice = {
						...part.price,
						productSuggestion: toFormattedPrice(productSuggestionPrice),
						coreValuePerUnit: toFormattedPrice(coreValuePerUnit),
						listPricePerUnit: toFormattedPrice(listPricePerUnit),
						netPricePerUnit: toFormattedPrice(netPricePerUnit),
						specialOffer: !isEmpty(specialOffer) && {
							...specialOffer,
							specialOfferDiscountAbsolute: `- ${toFormattedPrice(specialOfferDiscountAbsolute)}`,
						},
					};
					const updatedPart = {
						...part,
						price: newPrice,
						shoppingDetails: {
							price: newPrice,
							availability: part.availability,
							dealerCustomerInfo,
						},
					};
					return updatedPart;
				});
				return { ...res, data: { ...res.data, partList } };
			}
			return res;
		})
		.catch(err => {
			throw err;
		});
}

function formatFasterAvailabilityShoppingDetails(shoppingDetails) {
	return shoppingDetails.map(item => {
		const { price } = item;
		const { listPricePerUnit, netPricePerUnit, coreValuePerUnit, specialOffer } = price || {};
		const { specialOfferDiscountAbsolute } = specialOffer || {};
		return {
			...item,
			price: {
				...price,
				listPricePerUnit: toFormattedPrice(listPricePerUnit),
				netPricePerUnit: toFormattedPrice(netPricePerUnit),
				coreValuePerUnit: toFormattedPrice(coreValuePerUnit),
				specialOffer: !isEmpty(specialOffer) && {
					...specialOffer,
					specialOfferDiscountAbsolute: `- ${toFormattedPrice(specialOfferDiscountAbsolute)}`,
				},
			},
		};
	});
}

export function getPartFasterAvailability(bodyData: { partNumber: string; dealer: BasketDealerInfoSummary }) {
	return requestData('POST', 'PART_FASTER_AVAILABILITY', { bodyData })
		.then(res => {
			const newShoppingDetails = formatFasterAvailabilityShoppingDetails(res.data?.shoppingDetails || []);
			return {
				...res.data,
				shoppingDetails: newShoppingDetails,
			};
		})
		.catch(err => {
			throw err;
		});
}

export function getLegalTexts(legalTextType: RetailerLegalTextType, gssnId: string) {
	return requestData('GET', 'RETAILER_LEGAL_TEXT', {
		interpolate: { legalTextType },
		data: { gssnId },
		useTimeZone: true,
	})
		.then((res: RetailerLegalTextResponse) => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getRemanPartOrEngineInfo(lang: string, partNumber: string, isoCountry) {
	return requestData('GET', 'GET_REMAN_PART_OR_ENGINE_INFO', {
		interpolate: { culture: lang, partNumber, isoCountry },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getRemanSuggestedEngines(lang: string, FIN, isoCountry) {
	return requestData('GET', 'REMAN_SUGGESTED_ENGINE_INFO', {
		interpolate: { FIN, culture: lang, isoCountry },
	})
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getRemanCountryInfo(isoCountry: string) {
	return requestData('GET', 'REMAN_COUNTRY_INFO', { data: { isoCountry } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getRemanCategories(FIN: string) {
	return requestData('GET', 'REMAN_VIN_CATEGORIES', { interpolate: { FIN } })
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function validateRemanFin(FIN: string) {
	return requestData('GET', 'REMAN_VALIDATE_FIN', { interpolate: { FIN } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getRemanPartInfoFin(lang: string, FIN: string, categoryId, isoCountry) {
	return requestData('GET', 'REMAN_PART_INFO_FIN', {
		interpolate: { culture: lang, FIN, categoryId, isoCountry },
	})
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getWebpartsBulkOrderTemplate() {
	return requestData('GET', 'WEBPARTS_BULK_ORDER_TEMPLATE', { isFile: true })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function placeOrderWithBulkOrderTemplate(formData) {
	return requestData('POST', 'WEBPARTS_BULK_ORDER_INSERT', { formData }, MIMETypes.MULTIPART)
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}

export function getWebpartsCampaignBanners(gssnId, userId, companyId, customerNumber) {
	return requestData('GET', 'WEBPARTS_CAMPAIGNS', {
		data: { gssnId, userId, companyId, customerNumber },
	})
		.then(res => {
			return res.data || [];
		})
		.catch(() => {
			return [];
		});
}

export function getWebpartsCampaignDetail(gssnId, userId, companyId, customerNumber, campaignId, pageIndex) {
	return requestData('GET', 'WEBPARTS_CAMPAIGN_DETAIL', {
		data: { gssnId, userId, companyId, customerNumber, pageIndex, pageSize: WEBPARTS_CAMPAIGN_ITEM_PER_PAGE },
		interpolate: { campaignId },
	})
		.then(res => {
			const data = res.data.webPartsCampaignSpecialOfferPositions.map(position => {
				const { listPricePerUnit, netPricePerUnit } = position.partPrice.price;
				return {
					...position,
					partPrice: {
						...position.partPrice,
						price: {
							listPricePerUnit: toFormattedPrice(listPricePerUnit),
							rawListPricePerUnit: listPricePerUnit,
							netPricePerUnit: toFormattedPrice(netPricePerUnit),
							rawNetPricePerUnit: netPricePerUnit,
						},
					},
				};
			});

			return {
				...res,
				data: {
					...res.data,
					webPartsCampaignSpecialOfferPositions: data,
				},
			};
		})
		.catch(err => {
			throw err;
		});
}

export function getDiagnosisLicenses() {
	const parseDiagnosisLicenseData = (response: DiagnosisLicensesResponse) => ({
		...response,
		data: response?.data?.map(toDiagnosisLicense),
	});
	return requestData('GET', 'GET_DIAGNOSIS_LICENSES', null, null, true)
		.then((res: DiagnosisLicensesResponse) => {
			return parseResponseWithHeaders(res, parseDiagnosisLicenseData);
		})
		.catch(err => {
			throw err;
		});
}

export function deleteDiagnosisBasket() {
	return requestData('DELETE', 'DELETE_DIAGNOSIS_BASKET')
		.then(res => res)
		.catch(err => {
			throw err;
		});
}

export function deleteDigitalBasket() {
	return requestData('DELETE', 'DELETE_DIGITAL_BASKET')
		.then(res => res)
		.catch(err => {
			throw err;
		});
}

export function deleteEquipmentBasket() {
	return requestData('DELETE', 'DELETE_EQUIPMENT_BASKET')
		.then(res => res)
		.catch(err => {
			throw err;
		});
}

export function deleteWebpartsBasket(data) {
	return requestData('DELETE', 'DELETE_WEBPARTS_BASKET', {
		bodyData: { deleteBasketPositionRequests: data },
	})
		.then(res => res)
		.catch(err => {
			throw err;
		});
}

export const getPartsBasketXFRFile = (params = {}) => {
	const deselectedPartIds =
		JSON.stringify(getDeselectedProducts()).replace(ALL_SQUARE_BRACKETS, '').split(',') || undefined;
	const data = deselectedPartIds && deselectedPartIds?.[0] !== '' ? { deselectedPartIds, ...params } : { ...params };
	return requestData('GET', 'PARTS_BASKET_XFR_EXPORT', {
		data,
		isFile: true,
	}).then(res => res);
};

export const getPartsBasketXLSXFile = (params = {}) => {
	const deselectedPartIds =
		JSON.stringify(getDeselectedProducts()).replace(ALL_SQUARE_BRACKETS, '').split(',') || undefined;
	const data = deselectedPartIds && deselectedPartIds?.[0] !== '' ? { deselectedPartIds, ...params } : { ...params };
	return requestData('GET', 'PARTS_BASKET_XLSX_EXPORT', {
		data,
		isFile: true,
	}).then(res => res);
};

export const getPartFileExtraction = ({ formData, fileType, vin }) => {
	const endpoint = fileType === MIMETypes.PDF ? 'PART_FILE_EXTRACTOR_PDF' : 'PART_FILE_EXTRACTOR_EXCEL';
	return requestData('POST', endpoint, { interpolate: { vin }, formData }, MIMETypes.MULTIPART).then(
		(res: PartFileExtractionResponse) => res
	);
};

export const validatePart = (params = {}) => {
	return requestData('GET', 'PART_VALIDATION', { data: { ...params } }).then(res => res);
};
