import { IconType } from '@isp/icon';
import { Image } from '@Umbraco/models';
import { SpecialOffer } from '@Apis/orders';
import { CatalogueReference, PartsBasket, PartSubBasketProduct } from '@Reducers/shop/models';
import { ServiceHistoryData } from '@IspTypes/vehicle/responseTypes';
import {
	DSBMileageType,
	DSBServiceKind,
	OilDetailType,
	DSBResponseStatus,
	DSBServiceType,
	AddWork,
} from '@Constants/vehicle';
import {
	CatalogueGroupTypes,
	CatalogueDataTypes,
	PartShoppingInfo,
	PartDetailAvailability,
	PartDetailPrice,
} from '@Constants/webparts';
import { FinalVehicleType } from '@Constants/product';

export interface VehicleActionType {
	CLEAR_VEHICLE_DETAILS: Function;
	GET_VEHICLE_DETAILS: Function;
	SET_VEHICLE_LIST_PAGE_INDEX: Function;
	SET_FILTERED_VEHICLE_LIST: Function;
	SET_VEHICLE_LIST_LAST_LOADED_INDEX: Function;
	GET_VEHICLE_INFO: Function;
	GET_VEHICLE_VIN_VALIDATION: Function;
	SAVE_VEHICLE: Function;
	DELETE_VEHICLE: Function;
	SET_DSB_SERVICES: Function;
	UPDATE_SERVICE_DOCUMENTATION_FORM: Function;
	SET_SELECTED_VEHICLE: Function;
	SET_HIGHLIGHTED_VEHICLE_VIN: Function;
	UPDATE_CHAPTER_NAV_BUTTON_STATUS: Function;
	TRIGGER_VALIDATION: Function;
	RESET_VALIDATION_COUNTER: Function;
	UPDATE_FILTERED_CHECKBOXES_STATUS: Function;
	UPDATE_ADDITIONAL_CHECKBOXES_STATUS: Function;
	TOGGLE_DSB_SAVED_NOTIFICATION: Function;
	UPDATE_SERVICE_DOCUMENTATION_FORM_ERRORS: Function;
	RESET_DOCUMENTATION_FORM: Function;
	UPDATE_DSB_EXPORT_ERROR: Function;
	UPDATE_KDM_EXPORT_ERROR: Function;
	UPDATE_DSB_DATA: Function;
	GET_USER_VEHICLES_OR_PART: Function;
	GET_VEHICLE_PARTS_BY_VIN_NUMBER: Function;
	GET_SEARCH_PARTS_BY_PART_NUMBER: Function;
	GET_VEHICLE_PARTS_BY_VIN_AND_PART_NUMBER: Function;
	SET_SEARCHED_VEHICLES: Function;
	SET_SEARCHED_PART: Function;
	UPDATE_GENERAL_SEARCH_KEYWORD: Function;
	TOGGLE_EXPORT_PDF_SELECTION: Function;
	ROUTE_VEHICLE_PAGE_SECTION: Function;
	GET_SERVICE_HISTORY: Function;
	GET_SERVICE_HISTORY_DETAIL: Function;
	GET_SERVICE_DOCUMENTATION: Function;
	GET_DSB_EDIT_DATA: Function;
	TOGGLE_ALL_SERVICES: Function;
	SET_SCROLL_TO_KDM: Function;
	GET_KDM_INFO: Function;
	GET_DWD_OPTION: Function;
	UPDATE_EXPLOSION_DRAWING_HIGHLIGHTED_PARTS: Function;
	GET_FILLING_TAB_DATA: Function;
	SET_VEHICLE_CARD_OPENED: Function;
	GET_CATALOGS_WITH_VIN: Function;
}

export interface PublicationTitles {
	de: string;
	en: string;
	fr: string;
	es: string;
	it: string;
}

export enum CustomerServiceMeasuresTypes {
	KDM = 'KDM',
}

export enum CallbackTypes {
	RECALL = 'RECALL',
}

export enum KDMTypes {
	KDM,
	RECALL,
}

export interface FieldMeasures {
	downloadEnabled: boolean;
	documentLink: string;
	kdmType: CustomerServiceMeasuresTypes | CallbackTypes | KDMTypes;
	publicationTitles: PublicationTitles;
}

export interface Vehicle {
	service: any;
	orderCounts?: any;
	logisticInfo?: any;
	vin?: string;
	id: number;
	type?: FinalVehicleType;
	organizationId: string;
	vinNumber: string;
	vehicleModel: string;
	plateNumber: string;
	vehicleImageUrl: string;
	customerFullName: string;
	createDate: number;
	fieldMeasures: FieldMeasures[];
	isActive: boolean;
}

export interface ToBeDeletedVehicleInformation {
	vehicleImage: string;
	modelName: string;
	vinNumber: string;
	customerName?: string;
	licensePlate?: string;
}

export interface VehicleListModel {
	list: Vehicle[];
	totalCount: number;
}

export interface ServiceDocumentation {
	productionDate: number;
	registrationDate: number;
	servicePeriodMiles: number;
	servicePeriodKm: number;
	servicePeriodMonths: number;
	remainingDistanceMinValue: number;
	remainingDistanceMaxValue: number;
	remainingTimeMaxValue: number;
	remainingTimeMinValue: number;
	isWorkshopCodeAvailable: boolean;
	lastDamageMileageType: DSBMileageType;
	lastDamageMileage: number;
}

export interface DSBServicesData {
	nextService: NextServiceInfo;
	services: CompletedService[];
	externalAPIStatus: DSBResponseStatus;
	serviceDocumentation: ServiceDocumentation;
}

export interface DSBDeliveryAdress {
	id: string;
	name: string;
	street: string;
	postalCode: string;
	town: string;
	region: string;
	country: string;
	isSelected: boolean;
}

export interface DSBServiceDetailsResponse {
	serviceType: DSBServiceType;
	serviceDate: string;
	jobCardNumber: string;
	mileage: { indicator: DSBMileageType; value: string };
	remainingDays: string;
	remainingMileage: string;
	nextServiceMileage: string;
	internalComment: string;
	customerComment: string;
	dealerPlace: string;
	dealerStreet: string;
	dealerDescription: string;
	deliveryAddresses: DSBDeliveryAdress[];
	regularServiceAddWork: boolean;
	repairPartDetails: AddWork[];
	oilDetails: any;
	operationCodes: any;
	workshopCode: string;
	startMileage: string;
	nextServiceDate: string;
	vanDetails: any;
	externalDealerCode: string;
	isCreatedToday: boolean;
	headlampCheckExecuted: boolean;
}

export interface CompletedService {
	serviceId: number;
	completedOnTime: boolean;
	date: number;
	serviceExtend: DSBServiceType;
	serviceKind: DSBServiceKind;
	additionalWork: number;
	mileage: DSBMileage;
	workshopCode: string;
	repairOrderNumber: string;
	isRegularInstruction: boolean;
	workshopName: string;
	additionalWorkSteps: string[];
	oilDetail: OilDetail[];
	notes: DSBNotes;
	reEntry: boolean;
	editableRemainingDays: number;
	showOptions: boolean;
	workShopCode: string;
	workShopName: string;
	headlampCheckExecuted: boolean;
}

export interface DSBNotes {
	internal: string;
	forTheCustomer: string;
}

export interface OilDetail {
	type: OilDetailType;
	quality: string;
}

export interface DSBMileage {
	value: number;
	indicator: DSBMileageType;
}

export interface NextServiceInfo {
	daysRemaining: number;
	mileage: DSBMileage;
}

export interface ReplacementChain {
	predecessorParts?: Part[];
	successorParts?: Part[];
}
export interface Part {
	partNumber: string;
	partNo?: string;
	description?: string;
	title: string;
	shoppingDetails: PartShoppingInfo;
	quantity: number;
	partDetails?: PartManufactoringInfo;
	tooltip?: string;
	codeValidity?: string;
	plantInformation?: FactoryInfoItem[];
	footNotes?: FootnoteInfoItem[];
	alternativePartsTypes?: AlternativePartTypes[];
	colorInfoAvailable?: boolean;
	plantInformationAvailable?: boolean;
	replacementChain?: ReplacementChain;
	es1Codes?: number[];
	fin?: string;
	catalogRef?: CatalogueReference;
	supplementalParts?: SupplementalPart[];
}

export interface SupplementalPart extends Part {
	name: string;
	partNoFormatted: string;
	partContext: PartContext;
	pictureAvailable?: boolean;
	parentPartNo: string;
}

export interface GeneralSearchPart extends Part {
	additionalDescription: string;
	isInvalidPart: boolean;
	isUnknownPart: boolean;
	isRemanPart: boolean;
	designation: string;
}

export interface SearchPart extends GeneralSearchPart {
	currency: string;
	listPricePerUnit: string;
	netPricePerUnit: string;
	coreValuePerUnit: string;
	vatRate: number;
	navContext?: CatalogueGroupSummary[];
	aggTypeId?: string;
	aggProductId?: string;
	modelId?: string;
	partInfo?: PartSearchPartInfo;
}

export interface PartColorCodeInfo {
	partNumber: string;
	title: string;
	codeValidity: string;
	codeValidityDetails: CodeValidityInfoItem[];
	codeValidityMatrix: CodeValidityMatrixItem[][];
}

export interface PartColorOptionsInfo {
	colorOptions: PartColorInfo[];
	partNumber: string;
	vin: string;
	isLegacyColor: boolean;
	title?: string;
	displayName?: string;
	quantity: number;
	shoppingDetails: PartShoppingInfo;
	webpartsError: boolean;
	footNotes: FootnoteInfoItem[];
	catalogRef?: CatalogueReference;
}

export interface PartAlternativesInfo {
	alternativeParts: AlternativeParts[];
	vin: string;
	displayName?: string;
	title: string;
	description: string;
	partNumber: string;
	quantity: number;
	shoppingDetails: PartShoppingInfo;
	catalogRef?: CatalogueReference;
}

export interface FreeTextSearchResult {
	totalCount: number;
	searchResults: FreeTextSearchPart[];
}

export interface CatalogueGroupSummary {
	id: string;
	label: string;
	type: CatalogueGroupTypes;
}

export interface FreeTextSearchPart {
	name: string;
	modelId: string;
	partNumber: string;
	aggTypeId: string;
	description: string;
	materialDescription: string;
	groups: CatalogueGroupSummary[];
	navContext: NavigationInfoItem[];
	aggProductId: string;
}

export interface PartContext {
	moduleId: string;
	sequenceId: string;
}

export interface CalloutPart extends Part {
	calloutId: string;
	damageCodes: string[];
	level: string;
	name: string;
	displayName: string;
	partContext: PartContext;
	partNoFormatted: string;
	pictureAvailable: boolean;
	steering: any;
	navContext?: CatalogueGroupSummary[];
}

export enum AlternativePartTypes {
	REMAN_PART = 1,
	STAR_PART = 2,
}

export interface AlternatePartWithShoppingDetails extends AlternativeParts {
	shoppingDetails: PartShoppingInfo;
}

export interface AlternativeParts {
	es1Key: string;
	name: string;
	partNo: string;
	partNoFormatted: string;
	type: AlternativePartTypes;
}

export interface ExplosionDrawingPart extends Part {
	position: string;
	displayName: string;
	descriptionIcons: IconType[];
	calloutId: string;
	imageIndices?: number[];
	selectedQuantity?: number;
	partContext: PartContext;
	isHighlighted?: boolean;
	navContext?: CatalogueGroupSummary[];
	packages?: RelatedPartPackage[];
	partInfo?: PartSearchPartInfo;
}

export interface PartItemModel extends ExplosionDrawingPart {
	availabilities?: {
		availability: PartDetailAvailability;
		orderDealerGssnId: string;
	}[];
	partPrice?: CampaignPartPrice;
	modelId?;
	aggProductId?;
	partInfo?;
	aggTypeId?;
	designation?;
}

export enum ValidationStatus {
	VALID = 'VALID',
	INVALID = 'INVALID',
	REPLACEABLE = 'REPLACEABLE',
	REPLACED = 'REPLACED',
	VALIDATION_ERROR = 'VALIDATION_ERROR',
	LOADING = 'LOADING',
}

export const VALID_PART_STATUSES = [ValidationStatus.REPLACEABLE, ValidationStatus.REPLACED, ValidationStatus.VALID];

export interface ReplaceablePart {
	partNo: string;
	partName: string;
	partDescription: string;
}

export interface NavContextItem {
	id: string;
	label: string;
	type: number;
}

export interface RedirectionInfo {
	aggProductId: string;
	modelId: string;
	navContext: NavContextItem[];
}
export interface ValidationPartItem {
	status: ValidationStatus;
	number: string;
	replacedPartNumber?: string;
	name: string;
	description?: string;
	quantity: number;
	validated: boolean;
	catalogInfo?: PartItemModel;
	fin?: string;
	replaceableParts?: ReplaceablePart[];
	index?: number;
	redirectionInfo?: RedirectionInfo;
}

export interface CampaignPartPrice {
	designation?: string;
	isInvalidPart: boolean;
	isUnknownPart: boolean;
	price: PartDetailPrice;
	specialOffer: SpecialOffer;
	position: {
		partNumber: string;
		quantity: number;
	};
	tooltip: string;
}

export interface RemanPartInfo {
	alternativePartsAvailable: boolean;
	calloutId: string;
	codeValidity: string;
	partContext: PartContext;
	name: string;
	partNoFormatted: string;
	pictureAvailable: boolean;
	steering: {
		id: string;
		informativeNote: string;
		isLeftHand: boolean;
	};
	footNotes?: FootnoteInfoItem[];
	alternativePartsTypes?: AlternativePartTypes[];
	colorInfoAvailable?: boolean;
	plantInformationAvailable?: boolean;
	replacementChain?: ReplacementChain;
	es1Codes?: number[];
	quantity: string;
	partDetails: PartDetails;
}

export interface PartSearchPartInfo {
	alternativePartsAvailable: boolean;
	calloutId: string;
	codeValidity: string;
	partContext: PartContext;
	name: string;
	description: string;
	displayName: string;
	level: string;
	partNo: string;
	partNoFormatted: string;
	pictureAvailable: boolean;
	steering: {
		id: string;
		informativeNote: string;
		isLeftHand: boolean;
	};
	footNotes?: FootnoteInfoItem[];
	alternativePartsTypes?: AlternativePartTypes[];
	colorInfoAvailable?: boolean;
	plantInformationAvailable?: boolean;
	replacementChain?: ReplacementChain;
	es1Codes?: number[];
	quantity: string;
	partDetails: PartDetails;
}

export interface PartDetails {
	alternativeParts: AlternativeParts[];
	codeValidityDetails?: CodeValidityInfoItem[];
	codeValidityMatrix?: CodeValidityMatrixItem[][];
	colors: PartColorInfo[];
	plantInformation?: FactoryInfoItem[];
}
export interface RemanPart extends RemanPartDetail {
	designation?: string;
	isInvalidPart: boolean;
	isUnknownPart: boolean;
	position: {
		partNumber: string;
		quantity: number;
		id: string;
		partNumberBulkUpdate: string;
	};
	tooltip: string;
	shoppingDetails: PartShoppingInfo;
	partInfo?: RemanPartInfo;
}

export interface RemanPartDetail {
	aggTypeId: string;
	aggProductId: string;
	calloutId: string;
	navContext: NavigationInfoItem[];
	modelId: string;
	name: string;
	partNo: string;
	score: number;
	description: string;
	materialDescription: string;
	plantInformation?: FactoryInfoItem[];
}

export interface FasterAvailablePart extends Part {
	fasterAvailabilityList: PartShoppingInfo[];
	fin?: string;
	fromBasket?: boolean;
	onPartRemove?: (part: PartSubBasketProduct) => Promise<PartsBasket>;
	basketPart?: PartSubBasketProduct;
	availabilityHint?: string;
}

export interface Callout {
	active: boolean;
	height: number;
	id: string;
	left: number;
	tooltip: string;
	top: number;
	width: number;
	x: number;
	y: number;
}

export interface ExplosionImage {
	imageUrl: string;
	imageAltText: string;
	thumbnailImageUrl: string;
	thumbnailImageText: string;
	callouts: Callout[];
}

export interface PartCatalogueItem {
	imageUrl?: any;
	id: string;
	label: string;
	images: Image;
	partCatalogItemType: CatalogueDataTypes;
}

export interface CatalogueListViewItem {
	id: string;
	label: string;
	displayId?: string;
	partCatalogItemType?: CatalogueDataTypes;
}

export interface Division {
	id: string;
	ids: string[];
	label: string;
	name: string;
	areas: Area[];
	imageUrl: string;
}

export interface Area {
	id: string;
	name: string;
	label: string;
}

export interface Series {
	id: string;
	label: string;
	name: string;
	models: Model[];
}

export interface Model {
	areaId: string;
	displayId: string;
	id: string;
	label: string;
	name: string;
	typeId: string;
	divisionId: string[];
}

export interface PackageCatalogueItem extends PartCatalogueItem {
	parts: ExplosionDrawingPart[];
}

export interface ServiceSummary {
	serviceId: number;
	date: number;
	serviceExtend: DSBServiceType;
	mileage: DSBMileage;
	editableRemainingDays: number;
}

export interface ServiceHistoryWithExtAPIStatus extends ServiceHistoryData {
	externalAPIStatus: DSBResponseStatus;
}

export interface CompanyAddress {
	name: string;
	street: string;
	postalCode: string;
	town: string;
	country: string;
}

export interface PartColorInfo {
	es2Key: string;
	name: string;
	codeValidity: string;
	shoppingDetails?: PartShoppingInfo;
}
export interface PartManufactoringInfo {
	alternativeParts: AlternativeParts[];
	plantInformation: FactoryInfoItem[];
	codeValidityDetails?: CodeValidityInfoItem[];
	codeValidityMatrix?: CodeValidityMatrixItem[][];
	colors: PartColorInfo[];
}

export interface CodeValidityInfoItem {
	code: string;
	name: string;
	dateFrom?: string;
}

export interface CodeValidityMatrixItem {
	code: string;
	negative: boolean;
	name?: string;
}
interface PackagePartSummary {
	name: string;
	number: string;
	quantity: string;
}

export interface RelatedPartPackage {
	parts: PackagePartSummary[];
	constructionGroupId: string;
	id: string;
	name: string;
}

export interface PartSearchRelatedPackages {
	packages: RelatedPartPackage[];
	selectedSubGroupId?: string;
	vin: string;
}

export interface PartFootnoteRelatedPackages {
	searchKey: string;
	partPackages: RelatedPartPackage[];
}

export interface FreeTextSearchAllResult {
	searchTerm: string;
	vin?: string;
	modelId?: string;
	modelRange?: string;
}

export interface ModelRangeSelectionModalProps {
	results: FreeTextSearchWithoutContextItem[];
}
export interface FreeTextSearchWithoutContextItem {
	modelRange: string;
	partName: string;
	partNumber: string;
}

export interface FreeTextRefinedSearchResult {
	aggTypeId: string;
	calloutId: string;
	navContext: NavigationInfoItem[];
	modelId: string;
	name: string;
	partNumber: string;
	score: number;
	description: string;
	materialDescription: string;
	aggProductId?: string;
	moduleId?: string;
	catalog?: string;
}

export interface NavigationInfoItem {
	id: string;
	label: string;
	type: number;
}

export interface PartModelFilter {
	id: string;
	active: boolean;
	modelId: string;
}

export interface FootnoteInfoItem {
	id: string;
	text: string;
	type: string;
}

export interface FactoryInfoItem {
	date: string;
	exceptionIdents: string;
	ident: string;
	plant: string;
	type: string;
	text: string;
}

export interface PartPriceAvailabilityResponse {
	partList: SearchPart[];
}

export interface VehicleNoteWithVin {
	vin: string;
	vehicleNote: string;
}
