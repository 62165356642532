import { LocalStorageKeys } from '@Helpers/storage/localStorage';

export enum TestAccountParameters {
	CUSTOMER_NUMBER = 'customerNumber',
	HOOK_URL = 'hookUrl',
	GSSN_ID = 'gssnId',
	COMPANY_ID = 'companyId',
}

export const TEST_ACCOUNT_PARAMETER_LOCAL_STORAGE_MAPPING = {
	[TestAccountParameters.CUSTOMER_NUMBER]: LocalStorageKeys.ASP_TEST_ACCOUNT_CUSTOMER_NUMBER,
	[TestAccountParameters.HOOK_URL]: LocalStorageKeys.ASP_TEST_ACCOUNT_HOOK_URL,
	[TestAccountParameters.GSSN_ID]: LocalStorageKeys.ASP_TEST_ACCOUNT_GSSN_ID,
	[TestAccountParameters.COMPANY_ID]: LocalStorageKeys.ASP_TEST_ACCOUNT_COMPANY_ID,
};
