import App from 'next/app';
import Router, { withRouter } from 'next/router';
import { connect, Provider } from 'react-redux';
import get from 'lodash/get';
import withReduxStore from '@Lib/with-redux-store';
import withDefaultNamespace from '@Lib/withDefaultNamespace';
import NextI18Next from '@Lib/i18n';
import NAMESPACES from '@Lib/namespaces';
import TriggerEvent from '@Helpers/common/TriggerEvent';
import { WithTranslation } from 'next-i18next';
import '@isp/base';
import '../public/static';
import { isCookieAccepted, clearAcceptedCookies, clearExternalCookies } from '@Helpers/cookies/manageCookies';
import { scrollToCoordinates } from '@Helpers/common/document';
import ErrorBoundary from '@Layouts/errorBoundary';
import { handleOnMountScripts } from '@Helpers/scripts';
import * as ModalActions from '@Reducers/modals/actions';
import { bindActionCreators } from 'redux';
import { ModalActionType } from '@Reducers/modals/models';
import { EssentialCookies, MAX_COOKIE_SIZE } from '@Constants/cookies';
import { Events, RouterEvents, SCROLL_DISABLED_PAGES } from '@Constants/common';

interface AppState {
	loadingClassName?: string;
}

interface AppProps {
	reduxStore: any;
	pageProps: any;
	modalActions: ModalActionType;
}

declare global {
	interface Window {
		usercentrics;
	}
}

class MyApp extends App<AppProps & WithTranslation, AppState> {
	constructor(props) {
		super(props);
		this.routeChangeStart = this.routeChangeStart.bind(this);
		this.routeChangeComplete = this.routeChangeComplete.bind(this);
	}

	state = {
		loadingClassName: 'loading',
	};

	componentDidMount() {
		const { reduxStore, modalActions } = this.props;
		const { availableLanguages } = reduxStore.getState().configuration.languageConfiguration;
		if (!isCookieAccepted(EssentialCookies.COOKIE_CONSENT)) {
			clearAcceptedCookies();
			modalActions.TOGGLE_USERCENTRICS_BANNER_MODAL();
		}
		NextI18Next.i18n.reloadResources(availableLanguages, NAMESPACES);
		Router.events.on(RouterEvents.START, this.routeChangeStart);
		Router.events.on(RouterEvents.COMPLETE, this.routeChangeComplete);
		Router.events.on(RouterEvents.ERROR, this.routeChangeComplete);

		handleOnMountScripts();
	}

	componentDidUpdate() {
		if (!isCookieAccepted(EssentialCookies.COOKIE_CONSENT)) {
			clearAcceptedCookies();
			ModalActions.TOGGLE_USERCENTRICS_BANNER_MODAL();
		}
		if (get(document, 'cookie.length', 0) > MAX_COOKIE_SIZE) {
			clearExternalCookies();
		}
	}

	UNSAFE_componentWillUnmount() {
		Router.events.off(RouterEvents.START, this.routeChangeStart);
		Router.events.off(RouterEvents.COMPLETE, this.routeChangeComplete);
		Router.events.off(RouterEvents.ERROR, this.routeChangeComplete);
	}

	routeChangeStart() {
		const { router } = this.props;
		const { loadingClassName } = this.state;
		const html = document.getElementsByTagName('html')[0];

		if (!SCROLL_DISABLED_PAGES.includes(router.pathname)) {
			document.body.classList.add(loadingClassName);
			html.classList.add(loadingClassName);
		}

		TriggerEvent({
			event: Events.MOUSE_DOWN,
			element: document.body,
		});
	}

	routeChangeComplete() {
		const { router } = this.props;
		if (!SCROLL_DISABLED_PAGES.includes(router.pathname)) {
			scrollToCoordinates(0, 0);
		}
		const { loadingClassName } = this.state;
		const html = document.getElementsByTagName('html')[0];

		document.body.classList.remove(loadingClassName);
		html.classList.remove(loadingClassName);
	}

	public render() {
		const { Component, pageProps, reduxStore } = this.props;
		const getLayout = get(Component, 'getLayout') || (page => page);
		return (
			<ErrorBoundary>
				<Provider store={reduxStore}>{getLayout(<Component {...pageProps} />, pageProps)}</Provider>
			</ErrorBoundary>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	modalActions: bindActionCreators(ModalActions, dispatch),
});

export default NextI18Next.appWithTranslation(
	withDefaultNamespace(withReduxStore(withRouter(connect(null, mapDispatchToProps)(MyApp))))
);
