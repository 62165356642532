import { actionTypes } from '@Reducers/modals/actionTypes';
import { OrderPageLevel } from '@Constants/orders';
import { VehicleMoreInfoType, SearchTermType, ServiceDocumentationModalType } from '@Constants/vehicle';

export const initialModalState = {
	showImprintModal: false,
	showDealerLocator: false,
	showNotificationsMenu: false,
	showOrderRatingModal: false,
	showOrderDetailModal: false,
	showPartsOrderDetailsModal: false,
	partsOrderId: null,
	showDSBDetailModal: false,
	showChangeHardwareIdModal: false,
	showAccessRequestSuccessModal: false,
	showAccessRequestModal: false,
	showOrderSuccessModal: false,
	showOrderWithoutPaymentStatusModal: false,
	system: null,
	showServiceDocumentationModal: false,
	serviceDocumentationModalType: ServiceDocumentationModalType.NEW_ENTRY,
	showChangeDistanceUnitModal: false,
	userDistancePreference: null,
	showEnterVinModal: false,
	highlightedVehicleVIN: null,
	showAddVehicleModal: false,
	showExportPDFOptionsModal: false,
	selectedServiceIdsForPDFExport: [],
	showExportPDFSelectionOptions: false,
	showRegistrationModal: false,
	showUsercentricsSettingsModal: false,
	showUsercentricsInformationModal: false,
	showUsercentricsBanner: false,
	showRemanPartsModal: false,
	showRemanPartsRequestModal: false,
	remanPartsRequestProduct: {},
	isThereRelation: false,
	showFasterAvailableModal: false,
	showColorSelectionModal: false,
	searchTerm: '',
	response: null,
	searchTermType: SearchTermType.NOT_SET,
	selectedFasterAvailablePart: null,
	selectedPartColorInfo: null,
	vehicleMoreInfoType: VehicleMoreInfoType.NOT_SET,
	vehicleMoreInfoModalData: undefined,
	selectedService: null,
	showVehicleEditModal: false,
	showDeleteVehicleModal: false,
	showMegaMenu: false,
	megaMenuOptions: {},
	selectedVehicleCatalogSelectionOptions: null,
	selectedPartColorCodeInfo: null,
	selectedPartAlternativesInfo: null,
	selectedReplacementChainPart: null,
	showColorCodeInfoModal: false,
	showAlternatePartModal: false,
	showReplacementChainModal: false,
	showCompanyDataModal: false,
	showRequestCreatedModal: false,
	organizationName: null,
	organizationInfo: null,
	showPartPackageModal: false,
	selectedPartPackageInfo: null,
	showFreeTextSearchModal: false,
	showModelRangeSelectionModal: false,
	selectedVehicleAndText: null,
	modelRangeSelectionModalProps: null,
	orderDetailModalOrder: null,
	showFootnoteModal: false,
	selectedFootnoteItem: null,
	orderPageLevel: OrderPageLevel.VEHICLE_OVERVIEW_ORDERS,
	showNotificationBannerModal: false,
	selectedBannerNotification: null,
	showNewFeaturesModal: false,
	VINEditDisabled: false,
	vehicleInfoToBeDeleted: null,
	showAdditionalInfoModal: false,
	showBulkOrderModal: false,
	bulkOrderResult: null,
	informationForNotification: null,
	showOpeningHoursModal: false,
	showCatalogueFilterModal: false,
	showCatalogOptionSelectionModal: false,
	selectedApprovedDealerName: null,
	selectedApprovedDealerId: null,
	showLimitedAdminAccessModal: false,
	showContentReportingModal: false,
	showPublicSupportModal: false,
	selectedProductKey: null,
	showSpecialModificationVehicleModal: false,
	foCodesForLastAddedVehicle: [],
	showAggregateNumberSelectionModal: false,
	aggregate: null,
	showAddOldVehicleConfirmModal: false,
	addOldVehicleConfirmModalVin: null,
	addOldVehicleConfirmModalSaveVehicle: null,
	showBasketDeleteConfirmationModal: false,
	selectedBasketForBulkDelete: null,
	selectedCategoryForBulkDelete: null,
	showModelIdentificationModal: false,
	catalogPartSearchTerm: '',
	selectedRetailerForDelete: null,
	showRetailerDeleteConfirmationModal: false,
	showAddBasketDirectlyModal: false,
	showErrorModal: false,
	showLuckyDrawModal: false,
	showLuckyDrawParticipationSuccessModal: false,
	showNoActiveLuckyDrawModal: false,
	showUnlockViaPPVModal: false,
	xwisDocumentPartProps: null,
	showLoginErrorModal: false,
	loginErrorProps: null,
	recommendedPartProps: null,
	showInitialRegistrationModal: false,
	showAddCompanyMailModal: null,
	showAddNewPartnerModal: null,
	showPartsValidationIntegrationModal: false,
	showCancelPartsValidationImportModal: false,
	showRequestPurchaseModal: false,
	showTidyUpBasketModal: false,
	productKey: null,
	mailTo: null,
	confirmPartsValidationImportModalData: null,
	productPagePaymentModalData: null,
	showProductGalleryModal: false,
	productImages: [],
	product: null,
	productPageMoreInfoModalContent: null,
	showDSBStatusModal: false,
	validationPartList: [],
	retryAddToBasket: null,
	hideInitialRedirectionModalSelection: false,
};

export const modalsReducer = (state = initialModalState, action) => {
	switch (action.type) {
		case actionTypes.TOGGLE_IMPRINT_MODAL:
			return {
				...state,
				showImprintModal: action.showImprintModal,
			};
		case actionTypes.TOGGLE_DEALER_LOCATOR:
			return {
				...state,
				showDealerLocator: action.showDealerLocator,
			};

		case actionTypes.TOGGLE_ACCESS_REQUEST_SUCCESS:
			return {
				...state,
				showAccessRequestSuccessModal: action.showAccessRequestSuccessModal,
			};
		case actionTypes.TOGGLE_ACCESS_REQUEST:
			return {
				...state,
				showAccessRequestModal: action.showAccessRequestModal,
			};
		case actionTypes.TOGGLE_NOTIFICATIONS_MENU:
			return {
				...state,
				showNotificationsMenu: action.showNotificationsMenu,
			};
		case actionTypes.TOGGLE_ORDER_RATING_MODAL:
			return {
				...state,
				showOrderRatingModal: action.showOrderRatingModal,
				orderNameOnRatingModal: action.orderNameOnRatingModal,
				orderPageLevel: action.orderPageLevel,
			};
		case actionTypes.TOGGLE_ORDER_DETAIL_MODAL:
			return {
				...state,
				showOrderDetailModal: action.showOrderDetailModal,
				orderDetailModalOrder: action.orderDetailModalOrder,
				orderDetailModalType: action.orderDetailModalType,
			};
		case actionTypes.TOGGLE_PARTS_ORDER_DETAIL_MODAL:
			return {
				...state,
				showPartsOrderDetailsModal: action.showPartsOrderDetailsModal,
				partsOrderId: action.partsOrderId,
			};
		case actionTypes.TOGGLE_DSB_DETAIL_MODAL:
			return {
				...state,
				showDSBDetailModal: action.showDSBDetailModal,
				selectedService: action.selectedService,
			};
		case actionTypes.TOGGLE_CHANGE_HARDWARE_ID_MODAL:
			return {
				...state,
				showChangeHardwareIdModal: action.showChangeHardwareIdModal,
				system: action.system,
				selectedProductKey: action.selectedProductKey,
			};
		case actionTypes.TOGGLE_ORDER_SUCCESS_MODAL:
			return {
				...state,
				showOrderSuccessModal: action.showOrderSuccessModal,
			};
		case actionTypes.TOGGLE_ORDER_WITHOUT_PAYMENT_STATUS_MODAL:
			return {
				...state,
				showOrderWithoutPaymentStatusModal: action.showOrderWithoutPaymentStatusModal,
			};
		case actionTypes.TOGGLE_SERVICE_DOCUMENTATION_MODAL:
			return {
				...state,
				showServiceDocumentationModal: action.showServiceDocumentationModal,
				serviceDocumentationModalType: action.serviceDocumentationModalType,
			};
		case actionTypes.TOGGLE_CHANGE_DISTANCE_UNIT:
			return {
				...state,
				showChangeDistanceUnitModal: action.showChangeDistanceUnitModal,
				userDistancePreference: action.userDistancePreference,
			};
		case actionTypes.TOGGLE_ADD_VEHICLE_MODAL:
			return {
				...state,
				showAddVehicleModal: action.showAddVehicleModal,
				highlightedVehicleVIN: action.highlightedVehicleVIN,
				VINEditDisabled: action.VINEditDisabled,
			};
		case actionTypes.TOGGLE_ADDITIONAL_INFO_NOTIFICATION:
			return {
				...state,
				showAdditionalInfoModal: action.showAdditionalInfoModal,
				informationForNotification: action.informationForNotification,
			};
		case actionTypes.TOGGLE_DELETE_VEHICLE_MODAL:
			return {
				...state,
				showDeleteVehicleModal: action.showDeleteVehicleModal,
				vehicleInfoToBeDeleted: action.vehicleInfoToBeDeleted,
			};
		case actionTypes.TOGGLE_INITIAL_REGISTRATION_MODAL:
			return {
				...state,
				showInitialRegistrationModal: action.showInitialRegistrationModal,
			};
		case actionTypes.TOGGLE_EXPORT_PDF_MODAL:
			return {
				...state,
				showExportPDFOptionsModal: action.showExportPDFOptionsModal,
				selectedServiceIdsForPDFExport: action.selectedServiceIdsForPDFExport,
				showExportPDFSelectionOptions: action.showExportPDFSelectionOptions,
			};
		case actionTypes.OPEN_REGISTRATION:
			return {
				...state,
				showRegistrationModal: action.showRegistrationModal,
			};
		case actionTypes.CLOSE_REGISTRATION:
			return {
				...state,
				showRegistrationModal: action.showRegistrationModal,
			};
		case actionTypes.TOGGLE_USERCENTRICS_BANNER_MODAL:
			return {
				...state,
				showUsercentricsBanner: action.showUsercentricsBanner,
			};
		case actionTypes.TOGGLE_USERCENTRICS_SETTINGS_MODAL:
			return {
				...state,
				showUsercentricsSettingsModal: action.showUsercentricsSettingsModal,
			};
		case actionTypes.TOGGLE_USERCENTRICS_INFORMATION_MODAL:
			return {
				...state,
				showUsercentricsInformationModal: action.showUsercentricsInformationModal,
			};
		case actionTypes.TOGGLE_MORE_INFO_MODAL:
			return {
				...state,
				showMoreInfoModal: action.showMoreInfoModal,
			};
		case actionTypes.TOGGLE_REMAN_PARTS_MODAL:
			return {
				...state,
				showRemanPartsModal: action.showRemanPartsModal,
				searchTerm: action.searchTerm,
				response: action.response,
				searchTermType: action.searchTermType,
			};
		case actionTypes.TOGGLE_REMAN_PARTS_REQUEST_MODAL:
			return {
				...state,
				showRemanPartsRequestModal: action.showRemanPartsRequestModal,
				remanPartsRequestProduct: action.remanPartsRequestProduct,
				isThereRelation: action.isThereRelation,
			};
		case actionTypes.TOGGLE_FAST_AVAILABILITY_MODAL:
			return {
				...state,
				showFasterAvailableModal: action.showFasterAvailableModal,
				selectedFasterAvailablePart: action.selectedFasterAvailablePart,
			};
		case actionTypes.TOGGLE_COLOR_SELECTION_MODAL:
			return {
				...state,
				showColorSelectionModal: action.showColorSelectionModal,
				selectedPartColorInfo: action.selectedPartColorInfo,
			};
		case actionTypes.TOGGLE_VEHICLE_MORE_INFO_MODAL:
			return {
				...state,
				vehicleMoreInfoType: action.vehicleMoreInfoType,
				vehicleMoreInfoModalData: action.vehicleMoreInfoModalData,
			};
		case actionTypes.TOGGLE_VEHICLE_EDIT_MODAL:
			return {
				...state,
				showVehicleEditModal: action.showVehicleEditModal,
			};
		case actionTypes.TOGGLE_MEGA_MENU:
			return {
				...state,
				showMegaMenu: action.showMegaMenu,
				megaMenuOptions: action.megaMenuOptions,
			};
		case actionTypes.TRIGGER_CATALOG_OPTION_SELECTION_MODAL:
			return {
				...state,
				showCatalogOptionSelectionModal: action.showCatalogOptionSelectionModal,
				selectedVehicleCatalogSelectionOptions: action.selectedVehicleCatalogSelectionOptions,
			};
		case actionTypes.TOGGLE_CATALOGUE_FILTER_MODAL:
			return {
				...state,
				showCatalogueFilterModal: action.showCatalogueFilterModal,
			};
		case actionTypes.TOGGLE_COLOR_CODE_INFO_MODAL:
			return {
				...state,
				showColorCodeInfoModal: action.showColorCodeInfoModal,
				selectedPartColorCodeInfo: action.selectedPartColorCodeInfo,
			};
		case actionTypes.TOGGLE_ALTERNATE_PART_MODAL:
			return {
				...state,
				showAlternatePartModal: action.showAlternatePartModal,
				selectedPartAlternativesInfo: action.selectedPartAlternativesInfo,
			};
		case actionTypes.TOGGLE_REPLACEMENT_CHAIN_MODAL:
			return {
				...state,
				showReplacementChainModal: action.showReplacementChainModal,
				selectedReplacementChainPart: action.selectedReplacementChainPart,
			};
		case actionTypes.TOGGLE_COMPANY_DATA_EDIT_MODAL:
			return {
				...state,
				organizationInfo: action.organizationInfo,
				organizationName: action.organizationName,
				showCompanyDataModal: action.showCompanyDataModal,
			};
		case actionTypes.TOGGLE_REQUEST_CREATED_MODAL:
			return {
				...state,
				showRequestCreatedModal: action.showRequestCreatedModal,
			};
		case actionTypes.TOGGLE_PART_PACKAGE_MODAL:
			return {
				...state,
				showPartPackageModal: action.showPartPackageModal,
				selectedPartPackageInfo: action.selectedPartPackageInfo,
			};
		case actionTypes.TOGGLE_FREE_TEXT_SEARCH_MODAL:
			return {
				...state,
				showFreeTextSearchModal: action.showFreeTextSearchModal,
				selectedVehicleAndText: action.selectedVehicleAndText,
			};
		case actionTypes.TOGGLE_MODEL_RANGE_SELECTION_MODAL:
			return {
				...state,
				showModelRangeSelectionModal: action.showModelRangeSelectionModal,
				modelRangeSelectionModalProps: action.modelRangeSelectionModalProps,
			};
		case actionTypes.TOGGLE_FOOTNOTE_MODAL:
			return {
				...state,
				showFootnoteModal: action.showFootnoteModal,
				selectedFootnoteItem: action.selectedFootnoteItem,
			};
		case actionTypes.TOGGLE_BANNER_NOTIFICATION_MODAL:
			return {
				...state,
				showNotificationBannerModal: action.showNotificationBannerModal,
				selectedBannerNotification: action.selectedBannerNotification,
			};
		case actionTypes.TOGGLE_NEW_FEATURES_MODAL:
			return {
				...state,
				showNewFeaturesModal: action.showNewFeaturesModal,
			};
		case actionTypes.TOGGLE_BULK_ORDER_MODAL:
			return {
				...state,
				showBulkOrderModal: action.showBulkOrderModal,
				bulkOrderResult: action.bulkOrderResult,
			};
		case actionTypes.TOGGLE_RETAILER_OPENING_HOURS_MODAL:
			return {
				...state,
				showOpeningHoursModal: action.showOpeningHoursModal,
				selectedApprovedDealerName: action.selectedApprovedDealerName,
				selectedApprovedDealerId: action.selectedApprovedDealerId,
			};
		case actionTypes.TOGGLE_LIMITED_ADMIN_ACCESS_MODAL:
			return {
				...state,
				showLimitedAdminAccessModal: action.showLimitedAdminAccessModal,
			};
		case actionTypes.TOGGLE_UNLOCK_VIA_PPV_MODAL:
			return {
				...state,
				showUnlockViaPPVModal: action.showUnlockViaPPVModal,
			};
		case actionTypes.TOGGLE_CONTENT_REPORTING_MODAL:
			return {
				...state,
				showContentReportingModal: action.showContentReportingModal,
			};

		case actionTypes.TOGGLE_PUBLIC_SUPPORT_MODAL:
			return {
				...state,
				showPublicSupportModal: action.showPublicSupportModal,
			};
		case actionTypes.TOGGLE_SPECIAL_MODIFICATION_VEHICLE_MODAL:
			return {
				...state,
				showSpecialModificationVehicleModal: action.showSpecialModificationVehicleModal,
				foCodesForLastAddedVehicle: action.foCodesForLastAddedVehicle,
			};
		case actionTypes.TOGGLE_AGGREGATE_NUMBER_SELECTION_MODAL:
			return {
				...state,
				showAggregateNumberSelectionModal: action.showAggregateNumberSelectionModal,
				aggregate: action.aggregate,
				aggregateOption: action.aggregateOption,
			};
		case actionTypes.TOGGLE_ADD_OLD_VEHICLE_CONFIRM_MODAL:
			return {
				...state,
				showAddOldVehicleConfirmModal: action.showAddOldVehicleConfirmModal,
				addOldVehicleConfirmModalVin: action.addOldVehicleConfirmModalVin,
				addOldVehicleConfirmModalSaveVehicle: action.addOldVehicleConfirmModalSaveVehicle,
			};
		case actionTypes.TOGGLE_BASKET_BULK_DELETE_CONFIRMATION_MODAL:
			return {
				...state,
				showBasketDeleteConfirmationModal: action.showBasketDeleteConfirmationModal,
				selectedBasketForBulkDelete: action.selectedBasketForBulkDelete,
				selectedCategoryForBulkDelete: action.selectedCategoryForBulkDelete,
			};
		case actionTypes.TOGGLE_RETAILER_DELETE_CONFIRMATION_MODAL:
			return {
				...state,
				showRetailerDeleteConfirmationModal: action.showRetailerDeleteConfirmationModal,
				selectedRetailerForDelete: action.selectedRetailerForDelete,
			};
		case actionTypes.TOGGLE_ADD_BASKET_DIRECT_MODAL:
			return {
				...state,
				showAddBasketDirectlyModal: action.showAddBasketDirectlyModal,
			};
		case actionTypes.TOGGLE_PARTS_VALIDATION_INTEGRATION_MODAL:
			return {
				...state,
				showPartsValidationIntegrationModal: action.showPartsValidationIntegrationModal,
			};
		case actionTypes.TOGGLE_CANCEL_PARTS_VALIDATION_IMPORT_MODAL:
			return {
				...state,
				showCancelPartsValidationImportModal: action.showCancelPartsValidationImportModal,
				selectedVehicleCatalogSelectionOptions: action.selectedVehicleCatalogSelectionOptions,
			};
		case actionTypes.TOGGLE_PRODUCT_GALLERY_MODAL:
			return {
				...state,
				showProductGalleryModal: action.showProductGalleryModal,
				productImages: action.productImages,
			};
		case actionTypes.SET_CONFIRM_PARTS_VALIDATION_IMPORT_MODAL_DATA:
			return {
				...state,
				confirmPartsValidationImportModalData: action.confirmPartsValidationImportModalData,
			};
		case actionTypes.SET_PARTS_VALIDATION_LIST:
			return {
				...state,
				validationPartList: action.validationPartList,
			};
		case actionTypes.TOGGLE_TIDY_UP_BASKET_MODAL:
			return {
				...state,
				showTidyUpBasketModal: action.showTidyUpBasketModal,
				retryAddToBasket: action.retryAddToBasket,
			};
		case actionTypes.TOGGLE_ERROR_MODAL:
			return {
				...state,
				showErrorModal: action.showErrorModal,
			};
		case actionTypes.TOGGLE_LOGIN_ERROR_MODAL:
			return {
				...state,
				showLoginErrorModal: action.showLoginErrorModal,
				loginErrorProps: action.loginErrorProps,
			};
		case actionTypes.TOGGLE_MODEL_IDENTIFICATION_MODAL:
			return {
				...state,
				showModelIdentificationModal: action.showModelIdentificationModal,
				catalogPartSearchTerm: action.catalogPartSearchTerm,
				hideInitialRedirectionModalSelection: action.hideInitialRedirectionModalSelection,
			};
		case actionTypes.TOGGLE_LUCKY_DRAW_MODAL:
			return {
				...state,
				showLuckyDrawModal: action.showLuckyDrawModal,
			};
		case actionTypes.TOGGLE_LUCKY_DRAW_PARTICIPATION_SUCCESS_MODAL:
			return {
				...state,
				showLuckyDrawParticipationSuccessModal: action.showLuckyDrawParticipationSuccessModal,
			};
		case actionTypes.TOGGLE_NO_ACTIVE_LUCKY_DRAW_MODAL:
			return {
				...state,
				showNoActiveLuckyDrawModal: action.showNoActiveLuckyDrawModal,
			};
		case actionTypes.TOGGLE_REQUEST_PURCHASE_MODAL:
			return {
				...state,
				showRequestPurchaseModal: action.showRequestPurchaseModal,
				productKey: action.productKey,
				mailTo: action.mailTo,
			};
		case actionTypes.SET_SHOW_PART_XWIS_DOCUMENT_MODAL:
			return {
				...state,
				xwisDocumentPartProps: action.xwisDocumentPartProps,
			};
		case actionTypes.SET_RECOMMEDED_PART_MODAL:
			return {
				...state,
				recommendedPartProps: action.recommendedPartProps,
			};
		case actionTypes.SET_ADD_COMPANY_MAIL_MODAL_VISIBILITY:
			return {
				...state,
				showAddCompanyMailModal: action.showAddCompanyMailModal,
			};
		case actionTypes.SET_ADD_NEW_PARTNER_MODAL_VISIBILITY:
			return {
				...state,
				showAddNewPartnerModal: action.showAddNewPartnerModal,
			};
		case actionTypes.SET_PRODUCT_PAGE_PAYMENT_MODAL_DATA:
			return {
				...state,
				productPagePaymentModalData: action.productPagePaymentModalData,
			};
		case actionTypes.SET_PRODUCT_PAGE_MORE_INFO_MODAL_DATA:
			return {
				...state,
				productPageMoreInfoModalContent: action.productPageMoreInfoModalContent,
			};
		case actionTypes.TOGGLE_DSB_STATUS_MODAL:
			return {
				...state,
				showDSBStatusModal: action.showDSBStatusModal,
			};
		default:
			return state;
	}
};

export default modalsReducer;
