import { VehicleIdMapping } from '@Constants/vehicle';

export const CATALOGUE_PAGE_SOURCES = {
	VEHICLES: 'vehicles',
	BAUMUSTER: 'baumuster',
	PARTS_VALIDATION: 'parts-validation',
};

export const MATERIAL_PARTS_OIL_MAINGROUP_ID = '02';

export enum SearchedVehicleStatus {
	NONE,
	NONSAVED,
	SAVED,
}

export const BaumusterDivisionOrder = [VehicleIdMapping.PC, VehicleIdMapping.VAN, VehicleIdMapping.SMART];
