import get from 'lodash/get';
import { actionTypes } from '@Reducers/user/actionTypes';
import { initialUserState } from '@Reducers/user/reducers';
import {
	getUserInfo,
	updateTermsOfUseStatus,
	getRepublisherRequestStatus,
	requestRepublisherRole,
	getSatisfactionFeedbackScoreUserEnable,
	createSatisfactionFeedbackScore,
} from '@User-operations';
import { getRmiLicenses } from '@Apis/customer';
import { OrderResponseCode } from '@Constants/orders';
import { ProductKeys } from '@Constants/product';

export const LOGOUT = () => dispatch => {
	dispatch({
		type: actionTypes.LOGOUT,
		...initialUserState,
	});
};

export const TOGGLE_SHOW_TERMS_OF_USE_MODAL = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.TOGGLE_SHOW_TERMS_OF_USE_MODAL,
		loginTriggered: !getState().user.loginTriggered,
	});
};

export const SET_GA_CONSENT_STATUS = (gtmTracingAccepted: boolean) => dispatch => {
	dispatch({
		type: actionTypes.SET_GA_CONSENT_STATUS,
		gtmTracingAccepted,
	});
};

export const SET_TRACING_CONSENT_STATUS = (enableAppInsightTracing: boolean) => dispatch => {
	dispatch({
		type: actionTypes.SET_TRACING_CONSENT_STATUS,
		enableAppInsightTracing,
	});
};

export const GET_LICENSES = (productKey = null) => (dispatch, getState) => {
	const { isASPUser } = getState().user;
	if (!isASPUser)
		return getRmiLicenses(productKey)
			.then(res => {
				const licenseData = res?.data?.data;
				dispatch({
					type: actionTypes.GET_LICENSES,
					licenses: licenseData,
					licensesException: res?.data?.responseCode,
					digitalServiceBooklet:
						licenseData.find(item => item.productKey === ProductKeys.DIGITAL_SERVICE_BOOKLET) ||
						getState().user?.digitalServiceBooklet,
				});
				return res;
			})
			.catch(err => {
				dispatch({
					type: actionTypes.GET_LICENSES,
					licenses: null,
					licensesException: OrderResponseCode.SUCCESS,
				});
				throw err;
			});
	return null;
};

export const GET_USER_INFO = () => (dispatch, getState) => {
	return getUserInfo()
		.then(user => {
			dispatch({
				type: actionTypes.GET_USER_INFO,
				...getState().user,
				firstName: user.firstName,
				lastName: user.lastName,
				organization: user.organizationName,
				organizationId: user.organizationId,
				role: user.role,
				isFirstLogin: user.isFirstLogin,
				country: user.country,
				termsOfUse: user.termsOfUse,
				isUserLoggedIn: true,
				loginError: null,
			});
		})
		.catch(err => {
			dispatch({ type: actionTypes.GET_USER_INFO, ...initialUserState, err });
		});
};

export const GET_DIGITAL_SERVICE_BOOKLET_STATUS = () => dispatch => {
	dispatch({
		type: actionTypes.GET_DIGITAL_SERVICE_BOOKLET_STATUS,
		digitalServiceBooklet: {
			loading: true,
			serviceError: false,
		},
	});
	return getRmiLicenses(ProductKeys.DIGITAL_SERVICE_BOOKLET)
		.then(res => {
			const resData = res?.data;
			const dsb = resData?.data?.[0];
			const digitalServiceBooklet = { ...dsb, loading: false, serviceError: false };

			dispatch({
				type: actionTypes.GET_DIGITAL_SERVICE_BOOKLET_STATUS,
				digitalServiceBooklet,
			});
			return {
				digitalServiceBooklet,
			};
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_DIGITAL_SERVICE_BOOKLET_STATUS,
				digitalServiceBooklet: {
					loading: false,
					serviceError: true,
				},
			});
		});
};

export const UPDATE_TERMS_OF_USE_STATUS = data => (dispatch, getState) => {
	dispatch({
		type: actionTypes.UPDATE_TERMS_OF_USE_STATUS,
		updateTermsOfUseLoading: true,
	});
	return updateTermsOfUseStatus(data)
		.then(res => {
			dispatch({
				type: actionTypes.UPDATE_TERMS_OF_USE_STATUS,
				updateTermsOfUseLoading: false,
			});
			if (getState().user.loginTriggered) {
				dispatch(TOGGLE_SHOW_TERMS_OF_USE_MODAL());
			}
			dispatch(GET_USER_INFO());
			return res;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.UPDATE_TERMS_OF_USE_STATUS,
				updateTermsOfUseLoading: false,
				error: err,
			});
			throw err;
		});
};

export const GET_REPUBLISHER_REQUEST_STATUS = () => dispatch => {
	dispatch({
		type: actionTypes.GET_REPUBLISHER_REQUEST_STATUS,
		requestRepublisherStatusLoading: true,
		requestRepublisherStatusError: false,
		isRepublisherRoleRequested: false,
	});
	return getRepublisherRequestStatus()
		.then(res => {
			dispatch({
				type: actionTypes.GET_REPUBLISHER_REQUEST_STATUS,
				requestRepublisherStatusLoading: false,
				requestRepublisherStatusError: false,
				isRepublisherRoleRequested: get(res, 'data'),
			});
		})
		.catch(() => {
			dispatch({
				type: actionTypes.GET_REPUBLISHER_REQUEST_STATUS,
				requestRepublisherStatusLoading: false,
				requestRepublisherStatusError: true,
				isRepublisherRoleRequested: false,
			});
		});
};

export const REQUEST_REPUBLISHER_ROLE = () => dispatch => {
	dispatch({
		type: actionTypes.REQUEST_REPUBLISHER_ROLE,
	});
	return requestRepublisherRole()
		.then(res => {
			dispatch({
				type: actionTypes.REQUEST_REPUBLISHER_ROLE,
			});
			dispatch(GET_REPUBLISHER_REQUEST_STATUS());
			return res;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.REQUEST_REPUBLISHER_ROLE,
			});
			throw err;
		});
};

export const GET_SATISFACTION_FEEDBACK_SCORE_USER_ENABLE = () => dispatch => {
	const satisfactionFeedbackScoreUserEnable = false;

	dispatch({
		type: actionTypes.GET_SATISFACTION_FEEDBACK_SCORE_USER_ENABLE,
		satisfactionFeedbackScoreUserEnable,
	});
	return getSatisfactionFeedbackScoreUserEnable()
		.then(res => {
			dispatch({
				type: actionTypes.GET_SATISFACTION_FEEDBACK_SCORE_USER_ENABLE,
				satisfactionFeedbackScoreUserEnable: res.data,
			});
		})
		.catch(err => {
			dispatch({
				type: actionTypes.GET_SATISFACTION_FEEDBACK_SCORE_USER_ENABLE,
				satisfactionFeedbackScoreUserEnable,
			});
			throw err;
		});
};

export const CREATE_SATISFACTION_FEEDBACK_SCORE = data => dispatch => {
	dispatch({
		type: actionTypes.CREATE_SATISFACTION_FEEDBACK_SCORE,
	});
	return createSatisfactionFeedbackScore(data)
		.then(res => {
			dispatch({
				type: actionTypes.CREATE_SATISFACTION_FEEDBACK_SCORE,
			});
			return res;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.CREATE_SATISFACTION_FEEDBACK_SCORE,
				error: err,
			});
			throw err;
		});
};
