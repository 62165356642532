import Router from 'next/router';
import { getOrCreateStore } from '@Lib/with-redux-store';
import { RootState } from '@Redux';
import routes, { getRoutesAs } from '@Routes';
import { MenuItem } from '@Umbraco/menu';
import { TEST_ACCOUNT_PARAMETER_LOCAL_STORAGE_MAPPING } from '@Constants/asp';

export const handleASPNavigation = () => {
	const store = getOrCreateStore({});
	const state: RootState = store.getState();
	if (!state.user.isUserLoggedIn) return;

	const isASPPath = Router.pathname.split('/')?.[2] === 'asp';

	if (!state.user.isASPUser && isASPPath) {
		Router.replace(routes.HOME, getRoutesAs(routes.HOME, { market: 'de', ...Router.query }));
	}
};

export const setTestAccountParameters = () => {
	const urlParams = new URLSearchParams(window.location.search);
	Array.from(urlParams).forEach(parameter => {
		if (parameter && parameter[1]) {
			localStorage.setItem(TEST_ACCOUNT_PARAMETER_LOCAL_STORAGE_MAPPING[parameter[0]], parameter[1]);
		}
	});
};

export const removeElementFromMenuById = (items: MenuItem[], id: string): MenuItem[] => {
	return items
		.filter(item => item.type !== id)
		.map(item => ({
			...item,
			children: item.hasChildren ? removeElementFromMenuById(item.children, id) : item.children,
		}));
};
